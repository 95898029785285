import React, { useRef, useState, useContext } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';

// redux
import { useAppDispatch } from 'redux/store';
import { editQuizQuestion } from 'redux/actions/quizzes';

import NvFroala from 'froala/components/nv-froala';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { config } from '../../../config/pendo.config.json';

type Props = {
  className?: string;
};

const defaultQuestionTemplate = '<p class="froala-style-medium"><br /></p>';

const QuizQuestionContent = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const mountedRef = useRef(false);
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = useContext(ProgressiveQuizContext);

  const {
    isStatement,
    isEditMode,
    getQuestionLabelElementId,
  } = useQuizModeAndQuestionType();

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const { currentQuestion } = useContext(QuestionContext);
  const angularServices = useContext(AngularServicesContext);
  const { registerSaving } = useContext(SavingRegistryContext);
  const [value, setValue] = useState(currentQuestion.questionText || defaultQuestionTemplate);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [imageSrc, setImageSrc] = useState();

  const handleChange = (html) => {
    const patch = {
      questionText: html,
    };
    setValue(html);
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    dispatch(editQuizQuestion({
      patch,
      id: currentQuestion.id,
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const handleOnClick = (e) => {
    if (e.target.tagName === 'IMG') {
      setShowFullScreen(true);
      setImageSrc(e.target.src);
    }
  };

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  const getPlaceholder = () => {
    if (isStatement) {
      return t.QUIZZES.STATEMENT.TYPE_STATEMENT();
    }
    return t.QUIZZES.QUESTION_PLACEHOLDER();
  };

  const normalizeLists = (container, tagName) => {
    const tags = container.querySelectorAll(tagName.toLowerCase());
    if (tags.length <= 1) return;

    const extraTags = tags.length - 1;
    // an extra tag give an indent in redactor editor, while it is equalent to 20px margin in froala editor
    const marginLeft = extraTags * 20;

    // removing extra UL or OLs
    tags.forEach((tag) => {
      while (tag.firstChild) {
        container.appendChild(tag.firstChild);
      }
      tag.remove();
    });

    // setting required margin corresponding to indend
    container.style.marginLeft = `${marginLeft}px`;
  };

  /**
   * Giving an indent in the Redactor editor (the editor used in the old quiz UI)
   * adds an extra <ul> or <ol> element. In the Froala editor, this extra
   * <ul> or <ol> is processed in an undesired way. To rectify this issue,
   * pre-processing is applied to the value before passing it to Froala.
   * Each indent in the Redactor editor is equivalent to a 20px margin in the Froala editor.
   * So, we eliminate the nested extra <ul> or <ol> elements and
   * apply a margin of (number of removed tags * 20px) to the parent element.
   *
   * this processing will be needed only for the existing old UI All-question-view quiz,
   * in future we dont need this processing, we can pass value directly to froala
   * without processing
   */
  const processNestedListsHtml = (htmlString: string) => {
    const parser = new DOMParser();
    // coverting html string to html doc
    const doc = parser.parseFromString(htmlString, 'text/html');

    // function to find UL or OL element in doc and remove the nested tags(UL or OL)
    function flattenLists(children: HTMLCollection) {
      Array.from(children).forEach((child) => {
        if (child.tagName === 'UL') {
          normalizeLists(child, 'UL');
        } else if (child.tagName === 'OL') {
          normalizeLists(child, 'OL');
        } else {
          flattenLists(child.children);
        }
      });
    }
    flattenLists(doc.body.children);

    return doc.body.innerHTML;
  };

  if (isEditMode) {
    return (
      <NvFroala
        allowToolbar
        value={processNestedListsHtml(value)}
        minHeight={80}
        className={className}
        onChange={handleChange}
        placeholder={getPlaceholder()}
        dataQa={dataQaQuizAttributes.questionModal.questionTitle}
        dataQaId={`${dataQaQuizAttributes.questionModal.questionTitle}_${currentQuestion.id}`}
      />
    );
  }

  return (
    <React.Fragment>
      <ResponsivelyEmbeddedAngularHTML
        className={className}
        angularServices={angularServices}
        template={processNestedListsHtml(currentQuestion.questionText)}
        onClick={handleOnClick}
        id={getQuestionLabelElementId(currentQuestion.id)}
      />
      <NvModal
        type={ModalType.FULL}
        show={showFullScreen}
        onClose={() => setShowFullScreen(false)}
        header={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.FULL_SCREEN()}
        body={(
          <div className='w-100 h-100'>
            <img className='w-100' src={imageSrc} alt='' />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default QuizQuestionContent;
