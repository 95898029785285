import React from 'react';
import t from 'react-translate';
import moment from 'moment';

// redux
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityTitle from 'shared/components/activity/activity-title';
import LectureComponentDeadline from 'components/lecture-component-deadline';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { LecturePageMode } from 'lecture_pages/components';
import { NProgressiveQuiz } from 'redux/schemas/models/progressive-quiz';
import { NAllQuestionViewQuiz } from 'redux/schemas/models/all-question-view-quiz';
import PointsComponent from './points-component';

type QuizComponentTitleContentProps = {
  type: ComponentType.QUIZ | ComponentType.PROGRESSIVE_QUIZ,
  mode: LecturePageMode,
  quiz: NAllQuestionViewQuiz & NProgressiveQuiz,
  currentLecture: NLecturePage,
  saveComponent: Function,
};
const QuizComponentTitleContent = (props: QuizComponentTitleContentProps) => {
  const { quiz, type, mode, currentLecture, saveComponent } = props;
  const isEditMode = mode === LecturePageMode.EDIT;
  const isDeadlineEditable = mode === LecturePageMode.EDIT || mode === LecturePageMode.LINKED_EDIT;
  const lastSavedAt = quiz?.unsubmittedSubmission?.lastSavedAt;
  const isUnlimitedAttempts = quiz.maximumAttempts === 0 || !quiz.maximumAttempts;

  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);

  const [title, setTitle] = React.useState<string>(quiz.title);

  const handleTitleInputChange = (newTitle: string) => setTitle(newTitle);

  const handleTitleBlur = () => saveComponent({
    title,
  });

  const getComponentName = () => {
    if (type === ComponentType.QUIZ) {
      return 'quiz';
    }
    return 'progressive-quiz';
  };

  return (
    <React.Fragment>
      <div className='mb-2'>
        <ActivityStatus icon='quiz' status={quiz.progress} />
      </div>
      <ActivityTitle
        status={quiz.progress}
        statusesTexts={{
          not_started: t.QUIZZES.TAKE(),
          completed: isUnlimitedAttempts ? t.FORM.DONE() : t.FORM.SUBMITTED(),
        }}
      />
      <div className={`${(type === ComponentType.PROGRESSIVE_QUIZ) ? 'mb-6' : ''} d-flex flex-column align-items-center w-100`}>
        <LectureComponentHeaderInput
          required
          value={title}
          editable={isEditMode}
          onBlur={handleTitleBlur}
          onChange={handleTitleInputChange}
        />
        { quiz.unsubmittedSubmission && (
          <div className='mb-2 bold text-success text-small'>
            {t.QUIZZES.SAVED(moment(lastSavedAt).format('MMM D, YYYY, hh:mm A'))}
          </div>
        )}
        <PointsComponent
          quiz={quiz}
          type={type}
          mode={mode}
        />
        <div className={quiz.expirationDate ? 'mt-2' : ''}>
          <LectureComponentDeadline
            isEdit={isEditMode}
            catalogId={catalogId}
            componentName={getComponentName()}
            lecturePageId={currentLecture.id}
            expirationDate={quiz.expirationDate}
            hasHardDeadlines={quiz.hardDeadline}
            onHardDeadlineChange={(hardDeadline) => {
              saveComponent({ hardDeadline });
            }}
            onDeadlineChange={(date) => {
              saveComponent({ expirationDate: date?.toISOString() });
            }}
            isDeadlineEditable={isDeadlineEditable}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuizComponentTitleContent;
