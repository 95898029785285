import React from 'react';
import { css } from '@emotion/core';
import {
  Container as DNDContainer,
  Draggable as DNDDraggable,
} from 'react-smooth-dnd';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { addQuizQuestionOption, editQuizQuestion } from 'redux/actions/quizzes';

import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import QuizQuestionOption from 'quizzes/components/question-types/multiple-choice-question/quiz-question-option';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import { largeSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { config } from '../../../../../config/config.json';

type Props = {
  className?: string,
};

const MultipleChoiceAnswerSection = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const { responseOptions, currentQuestion } = React.useContext(QuestionContext);
  const {
    canMakeStructuralChanges,
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isMultipleAnswer,
  } = useQuizModeAndQuestionType();

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const styles = css`
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
      overflow: visible;
    }

    .add-option {
      height: ${largeSpacing}px;
      padding: 0 ${threeQuartersSpacing}px;
    }
  `;

  const renderInstruction = () => (
    <div className='label gray-1 mb-2'>
      {isEditMode ? t.QUIZZES.EDIT_MULTIPLE_OPTION_INSTRUCTION(courseAliases) : t.QUIZZES.MULTIPLE_OPTION_INSTRUCTION(isMultipleAnswer.toString())}
    </div>
  );

  const isOneOptionLeft = currentQuestion.responseOptions.length === 1;

  const mapOptionsToElements = () => responseOptions.map((option, index) => {
    const OptionContainer = isEditMode ? DNDDraggable : 'div';

    return (
      <OptionContainer key={option.id} className='mb-4'>
        <QuizQuestionOption
          index={index}
          option={option}
          isDeleteDisabled={isOneOptionLeft}
        />
      </OptionContainer>
    );
  });

  if (isEditMode) {
    const handleAdd = () => {
      const unregister = registerSaving();
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);

      dispatch(addQuizQuestionOption({ questionId: currentQuestion.id, optionContent: '' })).then((res) => {
        if (isEmpty(res.error)) {
          setSavingStatus(SavingIndicator.SUCCESS_STATUS);
        } else {
          setSavingStatus(SavingIndicator.ERROR_STATUS);
        }
      }).finally(() => {
        unregister();
        savingIndicatorTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        }, 2000);
      });
    };

    const applyDrag = (arr, dragResult) => {
      const { removedIndex, addedIndex, payload } = dragResult;

      if ((removedIndex === null && addedIndex === null) || removedIndex === addedIndex) return;

      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      const patch = {
        newOrder: result,
        responseOption: result.reduce((acc, curr, index) => {
          acc[curr] = { index };

          return acc;
        }, {}),
      };

      const unregister = registerSaving();
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);

      dispatch(editQuizQuestion({
        patch,
        id: currentQuestion.id,
      })).then((res) => {
        if (isEmpty(res.error)) {
          setSavingStatus(SavingIndicator.SUCCESS_STATUS);
        } else {
          setSavingStatus(SavingIndicator.ERROR_STATUS);
        }
      }).finally(() => {
        unregister();
        savingIndicatorTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        }, 2000);
      });
    };

    const isAddDisabled = !canMakeStructuralChanges;

    return (
      <div css={styles} className={`d-flex flex-column ${className}`}>
        {renderInstruction()}
        <DNDContainer
          lockAxis='y'
          dragHandleSelector='.icon-drag-handle'
          onDrop={(e) => applyDrag(currentQuestion.responseOptions, e)}
        >
          {mapOptionsToElements()}
        </DNDContainer>
        <NvTooltip enabled={isAddDisabled} text={t.QUIZZES.ALREADY_RELEASED.OPTIONS(courseAliases)}>
          <ClickableContainer
            onClick={handleAdd}
            disabled={isAddDisabled}
            className={`text-regular align-self-start add-option ${isAddDisabled ? 'gray-4' : ' text-primary'}`}
            data-qa={dataQaQuizAttributes.questionModal.addOption}
          >
            <NvIcon icon='add' size='small' className='mr-2' />
            {/* Using polls translation to prevent creating new repeated one */}
            {t.LECTURE_PAGES.COMPONENTS.POLLS.ADD_OPTION()}
          </ClickableContainer>
        </NvTooltip>
      </div>
    );
  }

  return (
    <div css={styles} className={className}>
      {renderInstruction()}
      {mapOptionsToElements()}
    </div>
  );
};

export default MultipleChoiceAnswerSection;
