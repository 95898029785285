import { css } from '@emotion/core';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/courseFull';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { gray3, gray7 } from 'styles/global_defaults/colors';
import { largeSpacing, quarterSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import QuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import { NQuizQuestion } from 'redux/schemas/models/quiz';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import NvIcon from 'shared/components/nv-icon';
import t from 'react-translate';
import QuizHeader from './quiz-header';
import QuizQuestion from './quiz-question';
import QuizSubmitSection from './quiz-submit-section';
import NavigationArrows from './navigation-arrows';

const QuizContent = () => {
  const { headerColor } = useSelector<RootState, Course>(getCurrentCourse);
  const { brandColor } = useSelector<RootState, Institution>(getCurrentInstitution);
  const courseAliases = useSelector(getFlatCourseAliases);
  const accentColor = headerColor ?? brandColor ?? gray3;

  const styles = css`
    z-index: 0;
    height: 100%;
    border-top: ${quarterSpacing}px solid ${accentColor};

    .scroll {
      height: 100%;
      overflow-y: auto;
      padding-left: 70px;
      padding-right: 70px;

      .saving-container {
        z-index: 1;
        position: fixed;
        top: calc(100% - ${tripleSpacing}px);
        left: 50%;
        transform: translateX(-50%);
        height: ${largeSpacing}px;
        width: 75px;
        border-radius: ${threeQuartersSpacing}px;
        box-shadow: 0px ${quarterSpacing}px ${quarterSpacing}px rgba(0, 0, 0, 0.15)
      }

      .quiz-header {
        top: 0;
        z-index: 2;
      }

      .content-container {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;

        .quiz-question {
          z-index: 1;
        }

        .released-container {
          padding: 12px;
          background-color: ${gray7};
        }

        .question-image {
          flex-shrink: 0;
        }
      }
    }
  `;
  const {
    savingStatus,
    currentQuestion,
    scrollRef,
    canMakeStructuralChanges,
    currentQuestionIndex,
  } = useContext(QuizContext);

  const {
    setAnswerState,
    setAnswerInputError,
  } = useContext(QuestionContext);

  const {
    isAnswerMode,
    isEditMode,
  } = useQuizModeAndQuestionType();

  const quizContainerRef = React.useRef(null);

  useEffect(() => {
    if (currentQuestionIndex !== null) {
      if (quizContainerRef.current) {
        quizContainerRef.current.focus();
      }
      scrollRef.current.scrollTop = 0;
    }
  }, [currentQuestionIndex, scrollRef]);

  const renderSavingStatus = () => {
    switch (savingStatus) {
      case SavingIndicator.SAVING_STATUS:
        return (
          <div className='d-flex text-gray-2 bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-refresh spin icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.SAVING()}</div>
          </div>
        );
      case SavingIndicator.SUCCESS_STATUS:
        return (
          <div className='d-flex bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-check text-success icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.QUIZ_SAVED()}</div>
          </div>
        );
      case SavingIndicator.ERROR_STATUS:
        return (
          <div className='d-flex bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-warning text-danger icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.ERROR()}</div>
          </div>
        );
      case SavingIndicator.HIDDEN_STATUS:
        return null;
      default:
        return null;
    }
  };

  const unsetResponse = React.useCallback(() => {
    setAnswerState(null);
    setAnswerInputError(null);
  }, []);

  return (
    <div css={styles} tabIndex={-1} ref={quizContainerRef} className='position-relative'>
      <div className='scroll position-relative' ref={scrollRef}>
        <div className='content-container d-flex flex-column h-100'>
          {currentQuestion && (
          <>
            <QuizHeader className='quiz-header mb-2 position-sticky' />
            {!canMakeStructuralChanges && isEditMode && (
              <div className='released-container text-danger d-flex flex-row align-items-center mb-5'>
                <NvIcon icon='warning' size='small' className='mr-2' />
                <div className='label text-danger'>
                  {t.QUIZZES.ALREADY_RELEASED.DESCRIPTION(courseAliases)}
                </div>
              </div>
            )}
            <QuizQuestion
              currentQuestion={currentQuestion}
              className={`quiz-question ${!isAnswerMode ? 'pb-6' : ''} position-relative`}
            />
            {isAnswerMode
              && (
                <QuizSubmitSection
                  currentQuestion={currentQuestion as NQuizQuestion}
                  unsetResponse={unsetResponse}
                />
              )}
          </>
          )}
        </div>
        {(savingStatus !== SavingIndicator.HIDDEN_STATUS) && isEditMode && (
        <React.Fragment>
          {renderSavingStatus()}
        </React.Fragment>
        )}
      </div>
      {currentQuestion && (
      <NavigationArrows />
      )}
    </div>
  );
};

export default QuizContent;
