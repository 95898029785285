import React, { useCallback } from 'react';
import { QuizQuestionType } from 'redux/schemas/models/quiz';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import QuizContext from 'quizzes/components/context';
import { LecturePageMode } from 'lecture_pages/components';
import { ComponentType } from 'redux/schemas/models/lecture-component';

const useQuizModeAndQuestionType = () => {
  const {
    mode,
    currentQuestion,
    isAllQuestionViewQuiz,
    lecturePageMode,
    isEditQuestionMode,
    lectureComponent,
  } = React.useContext(QuizContext);

  const isEditMode = (() => {
    if (!isAllQuestionViewQuiz) {
      return mode === ProgressiveQuizMode.EDIT;
    }
    if (isEditQuestionMode) {
      return true;
    }
    return false;
  })();

  const isCurrentQuizType = useCallback((type: ComponentType) => (lectureComponent.type === type), [lectureComponent.type]);
  const getFeedbackElementId = (currentQuestionId: number) => `question_feedback_${currentQuestionId}`;
  const getQuestionLabelElementId = (currentQuestionId: number) => `question_label_${currentQuestionId}`;

  const isAnswerMode = (() => {
    if (!isAllQuestionViewQuiz) {
      return mode === ProgressiveQuizMode.ANSWER;
    }
    return lecturePageMode === LecturePageMode.VIEW;
  })();

  const isRestrictedEditMode = lecturePageMode === LecturePageMode.RESTRICTED_EDIT;
  const isReorderMode = lecturePageMode === LecturePageMode.REORDER;
  const isLinkedEditMode = lecturePageMode === LecturePageMode.LINKED_EDIT;

  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;
  const isMultipleChoiceQuestion = currentQuestion?.type === QuizQuestionType.MULTIPLE_CHOICE
    || currentQuestion?.type === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;
  const isMultipleAnswer = currentQuestion?.type === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;
  const isStatement = currentQuestion?.type === QuizQuestionType.STATEMENT;
  const isShortAnswerQuestion = currentQuestion?.type === QuizQuestionType.SHORT_TEXT_QUESTION
    || currentQuestion?.type === QuizQuestionType.ALL_QUESTION_VIEW_SHORT_TEXT_QUESTION;
  const isNumberAnswerQuestion = currentQuestion?.type === QuizQuestionType.NUMBER_QUESTION;
  const isLongAnswerQuestion = currentQuestion?.type === QuizQuestionType.LONG_ANSWER_QUESTION;
  const isLikertAnswerQuestion = currentQuestion?.type === QuizQuestionType.LIKERT_SCALE_QUESTION;
  const isMatchingQuestion = currentQuestion?.type === QuizQuestionType.MATCHING_QUESTION;

  return {
    isEditMode,
    isAnswerMode,
    isReviewMode,
    isMultipleChoiceQuestion,
    isMultipleAnswer,
    isStatement,
    isShortAnswerQuestion,
    isNumberAnswerQuestion,
    isLongAnswerQuestion,
    isLikertAnswerQuestion,
    isRestrictedEditMode,
    isReorderMode,
    isLinkedEditMode,
    isMatchingQuestion,
    isCurrentQuizType,
    getFeedbackElementId,
    getQuestionLabelElementId,
  };
};

export default useQuizModeAndQuestionType;
