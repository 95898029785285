import React, { MutableRefObject, useCallback } from 'react';
import _ from 'underscore';
import t from 'react-translate';
import { AngularContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { getLecturePage, getQuiz } from 'redux/selectors/timeline';
import { Institution } from 'redux/schemas/models/courseFull';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { Course } from 'redux/schemas/models/course';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import {
  getQuizQuestion,
  GetQuestionParams,
  reviewPreviousAttempt,
} from 'redux/actions/quizzes';
import {
  getProgressiveQuiz,
  getQuizQuestionResponse,
  getProgressiveQuizQuestion,
  getQuizQuestions,
  getQuizQuestionOptions,
} from 'redux/selectors/quizzes';
import {
  NQuizQuestion,
  QuizQuestionType,
  ResponseOption,
  QuizQuestion as TQuizQuestion,
} from 'redux/schemas/models/quiz';

import { ProgressiveQuizMode } from 'quizzes/components/mode';
import FailedQuizModal from 'quizzes/components/failed-quiz-modal';
import { gray3 } from 'styles/global_defaults/colors';
import { QuestionPopoverProvider } from 'quizzes/components/question-popover';
import { getCurrentCourse } from 'redux/selectors/course';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import useSavingRegistry, { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import QuizContent from './quiz-content';

type Props = {
  reveal: boolean;
  mode: ProgressiveQuizMode;
  lectureComponentId: number;
  closeModal: (skipCheck?: boolean) => void;
  questionIndex?: number
  isAllQuestionViewQuiz?: boolean
  forwardOnQuestionsModalCloseRef?: MutableRefObject<{ callback: Function, restrictModalClose: boolean }>;
};

const QuizModal = (props: Props) => {
  const {
    mode,
    reveal,
    closeModal,
    lectureComponentId,
    questionIndex: initialQuestionIndex,
    isAllQuestionViewQuiz,
    forwardOnQuestionsModalCloseRef,
  } = props;

  const dispatch = useAppDispatch();
  const savingRegistry = useSavingRegistry();
  const { isSaving } = savingRegistry;
  const scrollRef = React.useRef<HTMLDivElement>();
  const savingIndicatorTimeoutRef = React.useRef<null | NodeJS.Timeout>(null);
  const quizContainerRef = React.useRef(null);
  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const [fetchedData, setFetchedData] = React.useState(false);
  const [savingStatus, setSavingStatus] = React.useState(SavingIndicator.HIDDEN_STATUS);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const [StateManager] = injectServices(['StateManager']);

  const lectureComponent = useSelector((state: RootState) => getLectureComponent(state, lectureComponentId));
  const ownerLecturePage = useSelector((state: RootState) => getLecturePage(state, lectureComponent.lecturePageId));

  const quizId = isAllQuestionViewQuiz ? lectureComponent.quiz : lectureComponent.progressiveQuiz;

  const quiz = useSelector(
    (state: RootState) => (isAllQuestionViewQuiz ? getQuiz(state, quizId) : getProgressiveQuiz(state, quizId)),
  );

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, quiz.pointsConfiguration || 0)
  ));

  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => (getQuizQuestions(state, quizId, isAllQuestionViewQuiz)),
  );

  const [
    currentQuestionId,
    setCurrentQuestionId,
  ] = React.useState<number | null>(null);

  const questionByCurrentId: NQuizQuestion = useSelector((state: RootState) => getProgressiveQuizQuestion(state, currentQuestionId));

  const [
    currentQuestionIndex,
    setCurrentQuestionIndex,
  ] = React.useState<number>(isAnswerMode ? null : initialQuestionIndex);

  let currentQuestion = (currentQuestionId ? questionByCurrentId : questions?.[currentQuestionIndex]);

  // For modes that are not EDIT we need to wait till data is fetched to
  // consider the redux question a reliable source
  currentQuestion = (fetchedData || isEditMode) ? currentQuestion : undefined;

  const currentCourse = useSelector(getCurrentCourse);
  const currentQuestionResponse = useSelector(
    (state: RootState) => getQuizQuestionResponse(state, (isReviewMode ? currentQuestion?.previousAttemptResponses : currentQuestion?.responses)),
  );

  const failedQuestion = (currentQuestion?.completedQuestionAttempts === quiz.questionMaximumAttempts) && !currentQuestionResponse?.isCorrect;

  const canMakeStructuralChanges = currentCourse.isContentManagementCollection ? !quiz.learnerStartedSubmission : !ownerLecturePage.released;

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration.rewardsPointsProportionally;

  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(quiz.answerableQuestionsCount * pointsConfiguration.threshold) : 0;

  const notMetRequirement = useCallback(() => {
    if (questionByCurrentId) {
      if (questionByCurrentId?.type === QuizQuestionType.STATEMENT) {
        return false;
      }
      const questionsLeftCount = quiz.answerableQuestionsCount - (questionByCurrentId?.displayIndex - 1 + (failedQuestion ? 1 : 0));
      return isRequirementEnabled ? ((quiz.correctAnswersCount + questionsLeftCount) < requiredCorrectQuestionsCount) : false;
    }
    return false;
  }, [
    failedQuestion,
    isRequirementEnabled,
    quiz.answerableQuestionsCount,
    quiz.correctAnswersCount,
    questionByCurrentId,
    requiredCorrectQuestionsCount,
  ]);

  const setForwardOnQuestionsModalCloseRef = useCallback((callback: Function, restrictModalClose: boolean) => {
    forwardOnQuestionsModalCloseRef.current.restrictModalClose = restrictModalClose;
    forwardOnQuestionsModalCloseRef.current.callback = callback;
  }, [forwardOnQuestionsModalCloseRef]);

  React.useEffect(() => StateManager.registerStateChangeStart(
    isSaving,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ),
  [isSaving, StateManager]);

  const isSavingQuizContents = isSaving();

  React.useEffect(() => {
    if (setForwardOnQuestionsModalCloseRef) {
      if (isSavingQuizContents) {
        const confirm = () => {
          setForwardOnQuestionsModalCloseRef(() => {}, false);
          closeModal();
        };
        setForwardOnQuestionsModalCloseRef(() => {
          dispatch(openConfirmationDialog({
            cancelText: t.FORM.CANCEL(),
            confirmText: t.FORM.YES_SURE(),
            onConfirm: confirm,
            title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
            bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
          }));
        }, true);
      } else {
        setForwardOnQuestionsModalCloseRef(() => {}, false);
      }
    }
  }, [
    isSavingQuizContents,
    dispatch,
    closeModal,
    forwardOnQuestionsModalCloseRef,
    setForwardOnQuestionsModalCloseRef,
  ]);

  const fetchQuestion = React.useCallback((params?: Omit<GetQuestionParams, 'questionSetId'>) => wrapThunkAction(
    dispatch(getQuizQuestion({
      questionSetId: quizId,
      ...(params ?? {}),
    })),
  ).then((action) => {
    const fetchedQuestion = action.payload as TQuizQuestion;

    setCurrentQuestionId(fetchedQuestion.id);

    setCurrentQuestionIndex(fetchedQuestion.questionIndex);

    return fetchedQuestion;
  }), [dispatch, quizId]);

  // Answer mode get question
  React.useEffect(() => {
    if (isAnswerMode) {
      fetchQuestion().then(() => {
        setFetchedData(true);
      });
    }
  }, [isAnswerMode, fetchQuestion]);

  // Review mode get questions
  React.useEffect(() => {
    if (isReviewMode) {
      wrapThunkAction(dispatch(reviewPreviousAttempt({
        reveal,
        questionSetId: quizId,
      }))).then(() => {
        setFetchedData(true);
      });
    }
  }, [
    reveal,
    dispatch,
    isReviewMode,
    quizId,
  ]);

  React.useEffect(() => {
    if (currentQuestionIndex !== null) {
      if (quizContainerRef.current) {
        quizContainerRef.current.focus();
      }
      scrollRef.current.scrollTop = 0;
    }
  }, [currentQuestionIndex]);

  const { headerColor } = useSelector<RootState, Course>(getCurrentCourse);
  const { brandColor } = useSelector<RootState, Institution>(getCurrentInstitution);

  const accentColor = headerColor ?? brandColor ?? gray3;

  const contextValue = {
    mode,
    reveal,
    questions,
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz: quiz,
    currentQuestion,
    lectureComponent,
    notMetRequirement,
    isRequirementEnabled,
    currentQuestionIndex,
    currentQuestionResponse,
    setCurrentQuestionIndex,
    canMakeStructuralChanges,
    requiredCorrectQuestionsCount,
    savingIndicatorTimeoutRef,
    isSubmitting,
    setIsSubmitting,
    savingStatus,
    setSavingStatus,
    isAllQuestionViewQuiz,
    isEditQuestionMode: isAllQuestionViewQuiz,
    forwardOnQuestionsModalCloseRef,
    setForwardOnQuestionsModalCloseRef,
  };

  const [answerState, setAnswerState] = React.useState(null);
  const [answerInputError, setAnswerInputError] = React.useState(null);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const unsetResponse = React.useCallback(() => {
    setAnswerState(null);
    setAnswerInputError(null);
  }, []);

  React.useLayoutEffect(() => {
    unsetResponse();
  }, [currentQuestionId, unsetResponse]);

  const areAllOptionsCorrect = responseOptions?.every((responseOption) => !responseOption.isCorrect) ?? false;

  const questionContextValue = {
    answerState,
    setAnswerState,
    answerInputError,
    setAnswerInputError,
    responseOptions,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  };

  return (
    <SavingRegistryContext.Provider value={savingRegistry}>
      <ProgressiveQuizContext.Provider value={contextValue}>
        <QuestionContext.Provider value={questionContextValue} key={currentQuestion?.id}>
          <QuestionPopoverProvider>
            <QuizContent />
          </QuestionPopoverProvider>
          {isAnswerMode && (
          <FailedQuizModal />
          )}
        </QuestionContext.Provider>
      </ProgressiveQuizContext.Provider>
    </SavingRegistryContext.Provider>
  );
};

export default QuizModal;
