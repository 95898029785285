import React, { useEffect, useState, useContext, useRef } from 'react';
import t from 'react-translate';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { css } from '@emotion/core';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// redux
import { NQuizQuestion } from 'redux/schemas/models/quiz';
import { editQuizQuestion } from 'redux/actions/quizzes';

import {
  black,
  danger,
  gray2,
  gray4,
  gray5,
  primary,
  success,
  white,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { isRtl } from 'styles/global_defaults/media-queries';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import QuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import ViewAnswerFeedBack from 'quizzes/components/feedback-components/view-answer-feedback';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { isEmpty } from 'underscore';
import NvTooltip from 'shared/components/nv-tooltip';
import LimitSettingInput from './limit-setting-input';
import NumberAnswerSection from '../number-answer-question/number-answer-section';
import { config } from '../../../../../config/config.json';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
};

const getTicksAndFactor = (min, max) => {
  const range = max - min;
  let ticksArray = [];

  const setTicksArray = (factor) => {
    ticksArray[0] = min;
    if (min % factor > 0) {
      ticksArray[1] = min + factor - (min % factor);
    }
    let num = ticksArray[1] ?? ticksArray[0];
    while (num + factor < max) {
      ticksArray = [...ticksArray, num + factor];
      num += factor;
    }
    ticksArray.push(max);

    /**
     * inorder to prevent the overlapping of end numbers with their corresponding
     * adjacent numbers, removing the adjacent number from the ticks array if the
     * difference between it and its corresponding end number is less than half of the factor.
     */
    if ((ticksArray[1] - ticksArray[0]) < (factor / 2)) {
      ticksArray.splice(1, 1);
    }
    if ((ticksArray[ticksArray.length - 1] - ticksArray[ticksArray.length - 2]) < (factor / 2)) {
      ticksArray.splice(ticksArray.length - 2, 1);
    }
  };

  let factor = 1;
  if (range <= 10) {
    setTicksArray(1);
  }
  if (range > 10 && range <= 20) {
    factor = 2;
    setTicksArray(2);
  }
  if (range > 20 && range <= 50) {
    factor = 5;
    setTicksArray(5);
  }
  if (range > 50 && range <= 100) {
    factor = 10;
    setTicksArray(10);
  }
  if (range > 100 && range <= 1000) {
    factor = 100;
    setTicksArray(100);
  }
  return { ticksArray, factor };
};

const LikertScaleAnswerSection = (props: QuizQuestionProps) => {
  const { currentQuestion } = props;

  const [limits, setLimits] = useState(
    {
      minLimit: currentQuestion.leftLimit,
      maxLimit: currentQuestion.rightLimit,
    },
  );
  const [marks, setMarks] = useState(null);
  const [leftLabel, setLeftLabel] = useState(currentQuestion.horizontalAxisLabel);
  const [rightLabel, setRightLabel] = useState(currentQuestion.verticalAxisLabel);

  const [isLeftLimitInputFocused, setisLeftLimitInputFocused] = useState(false);
  const [isRightLimitInputFocused, setisRightLimitInputFocused] = useState(false);

  const dispatch = useDispatch();
  const {
    isEditMode,
    isAnswerMode,
    getFeedbackElementId,
    getQuestionLabelElementId,
  } = useQuizModeAndQuestionType();

  const {
    responseOptions,
    currentQuestionResponse,
  } = useContext(QuestionContext);

  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isHardDeadlineExpired,
    isContentManagementCollection,
  } = React.useContext(QuizContext);

  const { registerSaving } = React.useContext(SavingRegistryContext);
  const sliderRef = useRef(null);

  const { setValue, trigger, getValues } = useFormContext() || {};

  // To set the limits and label while closing the edit question modal.
  useEffect(() => {
    if (!isEditMode) {
      setLimits({
        minLimit: currentQuestion.leftLimit,
        maxLimit: currentQuestion.rightLimit,
      });
      setLeftLabel(currentQuestion.horizontalAxisLabel);
      setRightLabel(currentQuestion.verticalAxisLabel);
    }
  }, [currentQuestion, isEditMode]);

  // To set the response value in likert question for review or reveal mode of quiz
  useEffect(() => {
    if (currentQuestionResponse) {
      setValue(currentQuestion.id.toString(), currentQuestionResponse.response);
    }
  }, [currentQuestion.id, currentQuestionResponse, setValue]);

  // To set the marks on likert scale when both left and right limits are set
  useEffect(() => {
    if (limits.maxLimit !== null && limits.minLimit !== null) {
      const newMarks = {};
      getTicksAndFactor(limits.minLimit, limits.maxLimit).ticksArray.forEach((eachTick) => {
        newMarks[eachTick] = eachTick;
      });
      setMarks(newMarks);
    } else {
      setMarks(null);
    }
  }, [limits.maxLimit, limits.minLimit]);

  useEffect(() => {
    if (sliderRef.current) {
      const handle = sliderRef.current.querySelector('.rc-slider-handle');
      handle?.setAttribute('aria-describedby', getFeedbackElementId(currentQuestion.id));
    }
  }, [currentQuestion.id, getFeedbackElementId]);

  const { factor } = getTicksAndFactor(limits.minLimit, limits.maxLimit);

  const isDisabled = () => {
    /**
     * Disable the likert scale for if quiz
     * is not in answer mode, passed hard deadline, is in review mode and is in lesson library
     */

    if (
      currentQuestionResponse
      || !isAnswerMode
      || isHardDeadlineExpired
      || isContentManagementCollection
    ) {
      return true;
    }
    return false;
  };

  const topOfRange = parseFloat(responseOptions[0]?.topOfRange);
  const bottomOfRange = parseFloat(responseOptions[0]?.bottomOfRange);

  let maxValue = null;
  let minValue = null;

  if (topOfRange >= bottomOfRange) {
    maxValue = topOfRange;
    minValue = bottomOfRange;
  } else {
    minValue = topOfRange;
    maxValue = bottomOfRange;
  }

  const getBottomSliderMark = () => {
    // Hide the bottom slider marker when lower range is equal to or beyond the limits
    if (minValue <= limits.minLimit || minValue >= limits.maxLimit || !minValue) {
      return '';
    }
    return minValue?.toString() ?? '';
  };

  const getTopSliderMark = () => {
    // Hide the top slider marker when higher range is equal to or beyond the limits
    if (maxValue >= limits.maxLimit || maxValue <= limits.minLimit || !maxValue) {
      return '';
    }
    return maxValue?.toString() ?? '';
  };

  if (sliderRef.current) {
    const handle1 = sliderRef.current.querySelector('.rc-slider-handle-1');
    const handle2 = sliderRef.current.querySelector('.rc-slider-handle-2');

    handle1?.setAttribute('data-content', getBottomSliderMark());
    handle2?.setAttribute('data-content', getTopSliderMark());
  }

  const getRangeValue = () => {
    // if any of the range is not a number
    if (Number.isNaN(minValue) || Number.isNaN(maxValue)) {
      // if both ranges are not a number
      if (Number.isNaN(minValue) && Number.isNaN(maxValue)) {
        return [];
      }

      // If only one is not a number, return the other value twice as a range
      if (Number.isNaN(minValue)) {
        // checking max range value is within the limits
        if (limits.minLimit <= maxValue && maxValue <= limits.maxLimit) {
          return [maxValue, maxValue];
        }
      }
      // checking min range value is within the limits
      if (limits.minLimit <= minValue && minValue <= limits.maxLimit) {
        return [minValue, minValue];
      }
      return [];
    }

    // if the range values are out of the limits
    if ((minValue < limits.minLimit && maxValue < limits.minLimit)
      || (minValue > limits.maxLimit && maxValue > limits.maxLimit)) {
      return [];
    }

    return [minValue, maxValue];
  };

  const getHandleColor = () => {
    if (!currentQuestionResponse) {
      return primary;
    }
    if (currentQuestionResponse.isCorrect) {
      return success;
    }
    return danger;
  };

  const styles = css`
    .answer-slider {
      .slide {
        margin-top: ${tripleSpacing}px;
        .rc-slider-track {
          background-color: transparent;
        }
        .rc-slider-step {
          background-color: ${isDisabled() ? gray5 : gray4};
        }
        .rc-slider-disabled {
          background-color: transparent;
        }
        .rc-slider-handle-dragging, .rc-slider-handle: hover {
          ${!isDisabled() && css`
              background-color: ${white} !important;
              border-color: ${primary} !important;
              border-width: 4px;
              width: 24px !important;
              height: 24px !important;;
              margin-top: -${halfSpacing}px !important;
              box-shadow: 0 0 0 7px ${primary}33; // 33 with primary represent 0.2 opacity
              opacity: 1;
          `}
        }
        .rc-slider-handle {
          background-color: ${white};
          border-color: ${isDisabled() ? gray5 : gray4};
          border-width: 4px;
          width: 24px;
          height: 24px;
          margin-top: -${halfSpacing}px;
          opacity: 1;
          ${((getValues
            && (getValues()[currentQuestion.id] || getValues()[currentQuestion.id] === 0))
            || currentQuestionResponse)
            && css`
              background-color: ${getHandleColor()};
              border-color: ${white};
              border-width: 4px;
              width: 28px;
              height: 28px;
              margin-top: -12px;
          `}
        }
        // Do not show dot mark for the left limit and right limit when they are not factors.
        .rc-slider-dot:first-child {
          ${(limits.minLimit % factor !== 0)
            && css`
              display: none;
          `}
        }
        .rc-slider-dot:last-child {
          ${(limits.maxLimit % factor !== 0)
            && css`
              display: none;
          `}
        }
        .rc-slider-dot {
          position: absolute;
          border: 4px solid white;
          background-color: ${isDisabled() ? gray5 : gray4};
          width: 18px;
          height: 18px;
          top: calc(50% - 9px);
        }
        .rc-slider-mark-text {
          top: -${tripleSpacing}px;
          font-size: 18px;
          font-weight: 700;
          color: ${black};
        }
      }
    }
    .slider-container {
      position: relative;
      width: 100%;
      margin-top: 130px;
      .left-limit {
        position: absolute;
        // handling RTL switching, By default, CSS transform: translate(x, y) does not respect the direction of the document (LTR/RTL)
        transform: translate(calc(${isRtl() ? ('50% - 30px') : ('-50% + 30px')}), calc(-100% - 20px));
      }
      .right-limit {
        position: absolute;
        left: 100%;
        // handling RTL switching, By default, CSS transform: translate(x, y) does not respect the direction of the document (LTR/RTL)
        transform: translate(calc(${isRtl() ? ('50% + 30px') : ('-50% - 30px')}), calc(-100% - 20px));
      }
      .slide {
        padding-left: ${largeSpacing}px;
        padding-right: ${largeSpacing}px;
        .rc-slider-mark-text {
          display: none;
        }
        .rc-slider-rail {
          background-color: ${gray4};
        }
        .rc-slider-track {
          background-color: ${primary};
        }
        .rc-slider-dot-active {
          background-color: ${primary} !important;
          border: 4px solid white !important;
        }
        .rc-slider-dot {
          position: absolute;
          border: 4px solid white;
          background-color: ${gray4};
          width: 18px;
          height: 18px;
          top: calc(50% - 9px);

          // left-limit slider dot color should be primary on left limit input is focused
          :first-child {
            background-color: ${isLeftLimitInputFocused ? primary : gray4};
          }
          // right-limit slider dot color should be primary on right limit input is focused
          :last-child {
            background-color: ${isRightLimitInputFocused ? primary : gray4};
          }
        }
        .rc-slider-handle {
          background-color: ${primary} !important;
          border: 4px solid white !important;
          opacity: 1;
          width: 18px;
          height: 18px;
          top: 3px;
        }
        .rc-slider-handle-1::after, .rc-slider-handle-2::after {
          content: attr(data-content);
          position: absolute;
          font-size: 18px;
          font-weight: 700;
          top: -25px;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      }
    }
    .label-section {
      margin-top: 25px;
      .right-label {
        margin-left: auto;
      }
      .right-label, .left-label {
        width: 30%;
        input {
          background-color: white;
          border-radius: ${quarterSpacing}px;
          border-color: ${gray5};
          height: ${tripleSpacing}px;
          font-size: 16px;
          &::placeholder {
            color: ${gray2};
          }
        }
      }
    }
    .number-answer-section {
      margin-top: -${largeSpacing}px;
    }
  `;

  const handleChange = (e) => {
    setValue(currentQuestion.id.toString(), e);
    trigger(currentQuestion.id.toString());
  };

  const getAnswerValue = () => {
    if (getValues
      && (getValues()[currentQuestion.id] || getValues()[currentQuestion.id] === 0)
    ) {
      return getValues()[currentQuestion.id];
    }
    // default value
    if (!Number.isNaN(limits.maxLimit) && !Number.isNaN(limits.minLimit)) {
      return limits.minLimit + Math.round((limits.maxLimit - limits.minLimit) / 2);
    }
    return null;
  };

  const handleLabelChange = (e, isLeftLabel: boolean) => {
    if (isLeftLabel) {
      setLeftLabel(e.target.value);
    } else {
      setRightLabel(e.target.value);
    }

    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(editQuizQuestion({
      id: currentQuestion.id,
      patch: {
        horizontalAxisLabel: isLeftLabel ? e.target.value : leftLabel,
        verticalAxisLabel: !isLeftLabel ? e.target.value : rightLabel,
      },
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  // handle wrapper function is to wrap the handle element with NvTooltip to show tooltips on each handle
  const handleWrapper = (origin: React.ReactElement<React.HTMLAttributes<HTMLDivElement>>) => {
    // handle1 is for the bottom range value
    const isHandle1 = origin.props.className.includes('rc-slider-handle-1');

    // handle2 is for the upper range value
    const isHandle2 = origin.props.className.includes('rc-slider-handle-2');

    const getText = () => {
      if (topOfRange === bottomOfRange) {
        return t.QUIZZES.NUMBER_ANSWER_QUESTION.CORRECT_NUMBER();
      }

      if (isHandle1) {
        return t.QUIZZES.LIKERT_SCALE_QUESTION.CORRECT_NUMBER_FROM();
      }
      if (isHandle2) {
        return t.QUIZZES.LIKERT_SCALE_QUESTION.CORRECT_NUMBER_TO();
      }
      return null;
    };
    return (
      <NvTooltip offset={30} text={getText()}>
        {origin}
      </NvTooltip>
    );
  };
  const isBothLimitsValid = () => (
    limits.maxLimit !== null && limits.minLimit !== null
  );

  if (isEditMode) {
    return (
      <div css={styles}>
        <div className='semi-bold text-small'>
          {t.QUIZZES.LIKERT_SCALE_QUESTION.SCALE_RANGE()}
        </div>
        <div className='slider-container'>
          <NvTooltip text={t.QUIZZES.LIKERT_SCALE_QUESTION.LEFT_SCALE_LIMIT()}>
            <div className='left-limit'>
              <LimitSettingInput
                setLimits={setLimits}
                limits={limits}
                isMinLimitInput
                isFocused={isLeftLimitInputFocused}
                setIsFocused={setisLeftLimitInputFocused}
              />
            </div>
          </NvTooltip>
          <NvTooltip text={t.QUIZZES.LIKERT_SCALE_QUESTION.RIGHT_SCALE_LIMIT()}>
            <div className='right-limit'>
              <LimitSettingInput
                setLimits={setLimits}
                limits={limits}
                isFocused={isRightLimitInputFocused}
                setIsFocused={setisRightLimitInputFocused}
              />
            </div>
          </NvTooltip>
          <div ref={sliderRef} className='slide'>
            <Slider
              range
              reverse={isRtl()}
              step={0.1}
              /**
               *If no limits are set, the marks at the extreme ends of the Likert scale will not be displayed as per design.
               *Therefore, dummy values (0 and 1) are provided when both limits are not set.
              */
              min={isBothLimitsValid() ? limits.minLimit : 0}
              max={isBothLimitsValid() ? limits.maxLimit : 1}
              marks={marks ?? { 0: 0, 1: 1 }}
              handleRender={(origin) => handleWrapper(origin)}
              value={getRangeValue()}
            />
          </div>
        </div>
        <div className='label-section d-flex'>
          <NvTextInput
            placeholder={t.QUIZZES.LIKERT_SCALE_QUESTION.LEFT_LABEL_PLACEHOLDER()}
            className='left-label text-large-body'
            value={leftLabel}
            onChange={(e) => handleLabelChange(e, true)}
            maxLength={30}
            qata-qa={config.pendo.activities.quiz.questionModal.likertAnswerQuestion.addLeftLimitLabel}
          />
          <NvTextInput
            placeholder={t.QUIZZES.LIKERT_SCALE_QUESTION.RIGHT_LABEL_PLACEHOLDER()}
            className='right-label text-large-body'
            value={rightLabel}
            onChange={(e) => handleLabelChange(e, false)}
            maxLength={30}
            qata-qa={config.pendo.activities.quiz.questionModal.likertAnswerQuestion.addRightLimitLabel}
          />
        </div>
        <div className='number-answer-section'>
          <NumberAnswerSection currentQuestion={currentQuestion} />
        </div>
      </div>
    );
  }

  return (
    <div className='mb-5' css={styles}>
      <div className='semi-bold text-small'>
        {t.QUIZZES.LIKERT_SCALE_QUESTION.SCALE_RANGE()}
      </div>
      <div className='answer-slider'>
        <div ref={sliderRef} className='slide mb-4 px-4'>
          <Slider
            reverse={isRtl()}
            step={1}
            min={limits.minLimit}
            max={limits.maxLimit}
            marks={marks}
            value={getAnswerValue()}
            onChange={handleChange}
            onChangeComplete={handleChange}
            disabled={isDisabled()}
            ariaLabelledByForHandle={getQuestionLabelElementId(currentQuestion.id)}
          />
        </div>
      </div>
      <div className='d-flex bold'>
        <div>
          {leftLabel}
        </div>
        <div className='ml-auto'>
          {rightLabel}
        </div>
      </div>
      {currentQuestionResponse && (
        <ViewAnswerFeedBack currentQuestion={currentQuestion} />
      )}
    </div>
  );
};

export default LikertScaleAnswerSection;
