import React, { Dispatch, SetStateAction, useContext } from 'react';
import t from 'react-translate';
import { css } from '@emotion/core';
import { isEmpty } from 'underscore';

// redux
import { NQuizQuestion } from 'redux/schemas/models/quiz';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import { RootState } from 'redux/schemas';
import { wrapThunkAction } from 'redux/utils';
import { editAnswerFeedback } from 'redux/actions/quizzes';

import { danger, success } from 'styles/global_defaults/colors';
import ProgressiveQuizContext, { SavingIndicator } from 'quizzes/components/context';
import NvIcon from 'shared/components/nv-icon';
import NvFroala from 'froala/components/nv-froala';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { FocusAnswerFeedback } from './answer-feedback';
import { config } from '../../../../config/pendo.config.json';

type FeedBackInputProps = {
  isForCorrectAnswer: boolean;
  correctAnswerFeedback: string;
  incorrectAnswerFeedback: string;
  setCorrectAnswerFeedback?: Function;
  setIncorrectAnswerFeedback?: Function;
  currentQuestion: NQuizQuestion;
  focus: boolean;
  setFocusAnswerFeedback: Dispatch<SetStateAction<FocusAnswerFeedback>>
};

const FeedbackInput = (feedbackInputprops: FeedBackInputProps) => {
  const {
    isForCorrectAnswer,
    correctAnswerFeedback,
    incorrectAnswerFeedback,
    setCorrectAnswerFeedback,
    setIncorrectAnswerFeedback,
    currentQuestion,
    focus,
    setFocusAnswerFeedback,
  } = feedbackInputprops;
  const dispatch = useAppDispatch();
  const [isFeedbackDeleting, setIsFeedbackDeleting] = React.useState(false);
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = useContext(ProgressiveQuizContext);

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
        setFocusAnswerFeedback(null);
      }
    }
  }, [focus, setFocusAnswerFeedback]);

  const feedBackPlacehoder = isForCorrectAnswer
    ? t.QUIZZES.CORRECT_ANSWER_FEEDBACK_PLACEHOLDER()
    : t.QUIZZES.INCORRECT_ANSWER_FEEDBACK_PLACEHOLDER();

  const responseOptionId: number = useSelector(
    (state: RootState) => (getQuizQuestionOptions(state, currentQuestion?.id)[0].id),
  );

  const feedbackStyles = css`
    .feedback-content {
      p {
        color: ${isForCorrectAnswer ? success : danger}
      }
    }
  `;

  const updateFeedback = React.useCallback((explanationParam, secondaryExplanationParam) => {
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    wrapThunkAction(dispatch(editAnswerFeedback(
      {
        explanation: explanationParam,
        secondaryExplanation: secondaryExplanationParam,
        questionOptionId: responseOptionId,
      },
    ))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  }, [
    dispatch,
    responseOptionId,
    savingIndicatorTimeoutRef,
    setSavingStatus,
  ]);

  const handleFeedBackInputDelete = () => {
    if (isForCorrectAnswer) {
      setCorrectAnswerFeedback(null);
      setFocusAnswerFeedback(FocusAnswerFeedback.FOCUS_INCORRECT_ANSWER_FEEDBACK);
    } else {
      setIncorrectAnswerFeedback(null);
      setFocusAnswerFeedback(FocusAnswerFeedback.FOCUS_CORRECT_ANSWER_FEEDBACK);
    }
  };

  const handleChange = (html) => {
    if (isForCorrectAnswer) {
      setCorrectAnswerFeedback(html);
      updateFeedback(html, incorrectAnswerFeedback);
    } else {
      setIncorrectAnswerFeedback(html);
      updateFeedback(correctAnswerFeedback, html);
    }
  };

  return (
    <div css={feedbackStyles} className='d-flex mt-2'>
      <NvIcon
        icon={`${isForCorrectAnswer ? 'success' : 'error'}`}
        className={`${isForCorrectAnswer ? 'text-success' : 'text-danger'} mr-3 d-flex align-items-center`}
        size='small'
      />
      <NvFroala
        ref={inputRef}
        allowToolbar
        editorClass='no-padding'
        className='flex-grow-1 feedback-content'
        minHeight={threeQuartersSpacing}
        value={isForCorrectAnswer ? correctAnswerFeedback : incorrectAnswerFeedback}
        onChange={handleChange}
        placeholder={feedBackPlacehoder}
        dataQa={dataQaQuizAttributes.questionModal.editFeedback}
      />
      <NvTooltip text={t.NOVOED.DELETE()}>
        <ClickableContainer
          className='ml-3'
          disabled={isFeedbackDeleting}
          onClick={() => {
            setSavingStatus(SavingIndicator.SAVING_STATUS);
            clearTimeout(savingIndicatorTimeoutRef.current);
            setIsFeedbackDeleting(true);

            dispatch(editAnswerFeedback(
              {
                explanation: isForCorrectAnswer ? null : correctAnswerFeedback,
                secondaryExplanation: isForCorrectAnswer ? incorrectAnswerFeedback : null,
                questionOptionId: responseOptionId,
              },
            )).then((res) => {
              handleFeedBackInputDelete();
              if (isEmpty(res.error)) {
                setSavingStatus(SavingIndicator.SUCCESS_STATUS);
              } else {
                setSavingStatus(SavingIndicator.ERROR_STATUS);
              }
            }).finally(() => {
              savingIndicatorTimeoutRef.current = setTimeout(() => {
                setSavingStatus(SavingIndicator.HIDDEN_STATUS);
              }, 2000);
              setIsFeedbackDeleting(false);
            });
          }}
        >
          <NvIcon
            icon='trash'
            size='small'
            className='text-primary d-flex align-items-center'
            data-qa={dataQaQuizAttributes.questionModal.deleteAnswerFeedback}
          />
        </ClickableContainer>
      </NvTooltip>
    </div>
  );
};

export default FeedbackInput;
