import React, { useCallback, useContext, useEffect } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { isEmpty } from 'underscore';
import { useFormContext } from 'react-hook-form';

// redux
import { useAppDispatch } from 'redux/store';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { ResponseOption, QuizQuestionType, MultiChoiceMultiAnswerQuestionResponse } from 'redux/schemas/models/quiz';
import { RootState } from 'redux/schemas';
import { getQuestionAnswerFeedback } from 'redux/selectors/quizzes';
import { wrapThunkAction } from 'redux/utils';
import {
  editQuizQuestionOption,
  deleteQuizQuestionOption,
  addQuestionOptionFeedback,
  EditQuizQuestionOptionParams,
  editQuestionOptionFeedback,
  deleteQuestionOptionFeedback,
  editAnswerFeedback,
} from 'redux/actions/quizzes';

import NvIcon from 'shared/components/nv-icon';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import NvTooltip from 'shared/components/nv-tooltip';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ClickableContainer from 'components/clickable-container';
import { getAlphabetCharacter } from 'quizzes/components/utils';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { ReactComponent as CheckedBox } from 'styles/icons/checked-box.svg';
import { ReactComponent as UncheckedBox } from 'styles/icons/unchecked-box.svg';
import ProgressiveQuizContext,
{
  QuestionContext,
  SavingIndicator,
} from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import {
  gray4,
  gray5,
  gray7,
  danger,
  primary,
  success,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  ReactComponent as SelectedRadio,
} from 'styles/icons/selected-radio.svg';
import {
  ReactComponent as UnselectedRadio,
} from 'styles/icons/unselected-radio.svg';
import {
  halfSpacing,
  largeSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode } from 'lecture_pages/components';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { config } from '../../../../../config/pendo.config.json';

const defaultOptionTemplate = '<p class="froala-style-medium"><br /></p>';

type Props = {
  index: number;
  isDeleteDisabled: boolean;
  option: ResponseOption;
};

const QuizQuestionOption = (props: Props) => {
  const {
    index,
    option,
    isDeleteDisabled,
  } = props;

  const {
    mode,
    canMakeStructuralChanges,
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
    isHardDeadlineExpired,
    isContentManagementCollection,
    lectureComponent,
  } = React.useContext(ProgressiveQuizContext);

  const { getValues, setValue, trigger } = useFormContext() || {};

  const {
    isEditMode,
    isMultipleAnswer,
    isAnswerMode,
    isCurrentQuizType,
  } = useQuizModeAndQuestionType();

  const {
    answerState,
    setAnswerState,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  } = React.useContext(QuestionContext);

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const dispatch = useAppDispatch();
  const mountedRef = React.useRef(false);

  const getOptionFeedback = () => {
    /**
     *For All-question-view quiz we are using explanation field to set feedback of option
     *and option_feedback field for single question view quiz to set the same
     */
    if (isCurrentQuizType(ComponentType.QUIZ)) {
      return option.explanation;
    }

    return option.optionFeedback;
  };

  const courseAliases = useSelector(getFlatCourseAliases);
  const feedback = useSelector((state: RootState) => (isAllQuestionViewQuiz
    ? getQuestionAnswerFeedback(state, currentQuestion.id, currentQuestion.questionSetId)
    : null));
  const [isFocused, setIsFocused] = React.useState(false);
  const angularServices = React.useContext(AngularServicesContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [isFeedbackFocused, setIsFeedbackFocused] = React.useState(false);
  const [optionContent, setOptionContent] = React.useState(option.optionContent || defaultOptionTemplate);
  const [optionFeedback, setOptionFeedback] = React.useState(getOptionFeedback() || defaultOptionTemplate);

  useEffect(() => {
    if (isCurrentQuizType(ComponentType.QUIZ)) {
      if (currentQuestionResponse) {
        setOptionFeedback(feedback.filter((eachFeedback) => eachFeedback.id === option.id)[0]?.explanation);
      }
    }
  }, [currentQuestionResponse, feedback, isAllQuestionViewQuiz, option.id, isCurrentQuizType]);
  const params = useLecturePageParams();

  const status = currentQuestionResponse?.feedback?.includes(option.id);

  const hasStatus = !!status;

  const isCorrect = () => {
    if (!isAllQuestionViewQuiz) {
      return option.isCorrect;
    }
    if (selected()) {
      return feedback?.filter((eachFeedback) => eachFeedback.id === option.id)[0]?.isCorrect;
    }
    return false;
  };

  const isObject = (variable) => typeof variable === 'object' && variable !== null;
  const selected = () => {
    if (isEditMode) {
      return false;
    }
    // To highlight submitted option response only for multiple choice question
    if (!isMultipleAnswer && currentQuestionResponse) {
      return currentQuestionResponse.response === option.id;
    }
    // To highlight submitted option response for multiple choice multiple answer question
    if (currentQuestionResponse && typeof currentQuestionResponse.response === 'object') {
      return !!(currentQuestionResponse.response as MultiChoiceMultiAnswerQuestionResponse).filter(((optionId) => optionId === option.id)).length;
    }
    if (!isAllQuestionViewQuiz) {
      // To highlight option while selecting in learner mode
      if (isObject(answerState)) {
        return !!answerState[option.id];
      }
      return false;
    }
    if (isMultipleAnswer) {
      if (!getValues(currentQuestion.id.toString())) {
        return false;
      }
      if (!isEmpty((getValues(currentQuestion.id.toString()) as number[]).filter((optionId) => optionId === option.id))) {
        return true;
      }
      return false;
    }
    if (getValues(currentQuestion.id.toString()) === option.id) {
      return true;
    }
    return false;
  };

  const isLecturePageViewMode = params.mode === LecturePageMode.VIEW;

  const editNotFocusedBorderColor = option.isCorrect ? success : gray5;

  const setExplanation = useCallback((explanation: string) => {
    const unregister = registerSaving();
    wrapThunkAction(dispatch(editAnswerFeedback(
      { explanation,
        questionOptionId: option.id },
    ))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        unregister();
      }, 2000);
    });
  }, [dispatch, option.id, registerSaving, savingIndicatorTimeoutRef, setSavingStatus]);

  const handleChange = React.useCallback((patch: EditQuizQuestionOptionParams['patch']) => {
    clearTimeout(savingIndicatorTimeoutRef.current);
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);

    dispatch(editQuizQuestionOption({
      patch,
      id: option.id,
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  }, [
    dispatch,
    option.id,
    registerSaving,
    savingIndicatorTimeoutRef,
    setSavingStatus,
  ]);

  const handleDelete = () => {
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(deleteQuizQuestionOption(option.id)).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const withStatusColor = isCorrect() ? success : danger;

  const color = hasStatus ? withStatusColor : primary;

  const notEditSelectedBackgroundColor = hexToRgbaString(color, 0.05);

  const isSelectionDisabled = !!currentQuestionResponse;

  React.useEffect(() => {
    if (mountedRef.current) {
      handleChange({ optionContent });
    }
  }, [optionContent, handleChange]);

  React.useEffect(() => {
    if (mountedRef.current && isEditMode) {
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);

      if (isCurrentQuizType(ComponentType.QUIZ)) {
        setExplanation(optionFeedback);
      } else {
        const unregister = registerSaving();
        dispatch(editQuestionOptionFeedback({
          questionOptionId: option.id,
          feedback: optionFeedback,
        })).then((res) => {
          if (isEmpty(res.error)) {
            setSavingStatus(SavingIndicator.SUCCESS_STATUS);
          } else {
            setSavingStatus(SavingIndicator.ERROR_STATUS);
          }
        }).finally(() => {
          savingIndicatorTimeoutRef.current = setTimeout(() => {
            setSavingStatus(SavingIndicator.HIDDEN_STATUS);
          }, 2000);
          unregister();
        });
      }
    }
  }, [
    optionFeedback,
    dispatch,
    option.id,
    registerSaving,
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isEditMode,
    lectureComponent.type,
    setExplanation,
    isCurrentQuizType,
  ]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  const styles = css`
    background-color: white;

    .handle-container {
      width: ${largeSpacing}px;
      left: -${largeSpacing}px;

      .handle {
        visibility: hidden;
      }
    }

    &:hover {
      .handle {
        visibility: visible;
      }
    }

    .option-container {
      flex: 1;
      border-radius: 4px;
      ${isEditMode ? css`
        border: 1px solid ${isFocused ? primary : editNotFocusedBorderColor};
      ` : css`
        background-color: ${selected() ? notEditSelectedBackgroundColor : gray7};
        ${selected() && css`
          border: 1px solid ${color};
        `};
      `};

      .question-option-content {
        flex: 1;

        .fr-element {
          outline: 0!important;
          padding-top: 0!important;

          p:last-child {
            margin-bottom: 0;
          }

          ${isEditMode && option.isCorrect && css`
            color: ${success};
          `};
        }
      }

      .question-option-content-view {
        flex: 1;
      }

      .edit-options {
        top: 0;
        right: 0;
        opacity: 0;
      }

      .answer-checkbox {
        opacity: 0;
      }

      &:hover {
        ${!isContentManagementCollection
           && isAnswerMode
           && !isSelectionDisabled
           && !isHardDeadlineExpired && css`
          .answer-checkbox {
            opacity: 1;
          }

          .big-checkbox-alphabet-character {
            font-weight: bold;
          }
        `};

        .edit-options {
          opacity: 1;
        }
      }
    }

    .feedback-container {
      padding-right: ${threeQuartersSpacing}px;
      .feedback-content {
        .fr-element {
          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .feedback-icon {
        color: ${isCorrect() ? success : danger};
      }

      .feedback-content-container {
        flex: 1;
        min-height: ${largeSpacing}px;
        border: 1px solid transparent;

        ${!getOptionFeedback() && css`
          border-color: ${gray4};
          background-color: ${gray7};
        `};

        ${isFeedbackFocused && css`
          border-color: ${primary};
          background-color: transparent;
        `};

        .feedback-content {
          flex: 1;

          .fr-element {
            overflow-y: hidden;
            border: 0!important;
            outline: 0!important;
            padding-top: 0!important;
            padding-bottom: 0!important;
            background-color: transparent!important;
            padding-left: ${halfSpacing}px!important;
            padding-right: ${halfSpacing}px!important;
            color: ${((isEditMode ? (option.isCorrect || areAllOptionsCorrect) : option.isCorrect)) ? success : danger};

            p:last-child {
              margin-bottom: 0;
            }
          }

          .fr-placeholder {
            border: 0!important;
          }
        }
      }
    }
  `;

  const isDeleteOptionDisabled = !canMakeStructuralChanges;

  const defaultFeedback = isCorrect() ? t.SHARED.CORRECT() : t.SHARED.INCORRECT();
  const actualOptionFeedback = isEditMode ? getOptionFeedback() : (optionFeedback || defaultFeedback);

  const setMultipleChoiceValue = (value) => {
    setValue(currentQuestion.id.toString(), value);
    trigger(currentQuestion.id.toString());
  };

  const handleOptionSelect = () => {
    if (!isAllQuestionViewQuiz) {
      if (isMultipleAnswer) {
        setAnswerState(
          { ...(typeof answerState === 'object' ? answerState : {}),
            [option.id]: !selected() },
        );
      } else {
        setAnswerState({
          [option.id]: true,
        });
      }
    } else {
      if (isMultipleAnswer) {
        if (!selected()) {
          if (!getValues(currentQuestion.id.toString())) {
            setMultipleChoiceValue([option.id]);
            return;
          }
          setMultipleChoiceValue([...getValues(currentQuestion.id.toString()), option.id]);
          return;
        }
        const newOptions = getValues(currentQuestion.id.toString()).filter((optionId) => optionId !== option.id);
        setMultipleChoiceValue([...newOptions]);
        return;
      }
      if (!selected()) {
        setMultipleChoiceValue(option.id);
      }
    }
  };

  return (
    <div css={styles}>
      <div className='d-flex flex-row position-relative'>
        {isEditMode && (
          <div className='handle-container position-absolute h-100 d-flex align-items-center'>
            {/* Reusing this translation to not create a new one */}
            <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.DRAG_AND_DROP_TO_REORDER()}>
              <ClickableContainer
                className='handle'
                data-qa={dataQaQuizAttributes.questionModal.optionReorder}
                data-qa-id={`${dataQaQuizAttributes.questionModal.optionReorder}_${option.id}`}
              >
                <NvIcon icon='drag-handle' size='small' />
              </ClickableContainer>
            </NvTooltip>
          </div>
        )}
        <ClickableContainer
          layoutOnly={!isAnswerMode || isHardDeadlineExpired || isContentManagementCollection}
          disabled={isSelectionDisabled}
          onClick={handleOptionSelect}
          className='d-flex align-items-center p-3 option-container'
          data-qa={dataQaQuizAttributes.questionModal.option}
          data-qa-id={`${dataQaQuizAttributes.questionModal.option}_${option.id}`}
        >
          <BigCheckbox
            mode={mode}
            index={index}
            color={color}
            className='mr-2'
            selected={selected()}
            checked={option.isCorrect}
            characterClassName='big-checkbox-alphabet-character'
            onChange={(newCorrect) => handleChange({ isCorrect: newCorrect })}
            optionId={option.id}
            circularBorder={isLecturePageViewMode && !isMultipleAnswer}
          />
          {isEditMode ? (
            <NvFroala
              allowToolbar
              value={optionContent}
              minHeight={threeQuartersSpacing}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              className='question-option-content'
              placeholder={t.QUIZZES.INPUT_OPTION()}
              editorClass='no-background no-padding'
              onChange={(html) => setOptionContent(html)}
              dataQa={dataQaQuizAttributes.questionModal.optionContent}
              dataQaId={`${dataQaQuizAttributes.questionModal.optionContent}_${option.id}`}
            />
          ) : (
            <div className='question-option-content'>
              <ResponsivelyEmbeddedAngularHTML
                template={option.optionContent}
                angularServices={angularServices}
                className='fr-element question-option-content-view'
              />
            </div>
          )}
          {isEditMode && (
            <div className='d-flex align-items-center justify-content-center h-100 ml-2 edit-options'>
              {getOptionFeedback() === null && (
              <NvTooltip text={t.QUIZZES.ADD_FEEDBACK(courseAliases)}>
                <ClickableContainer
                  className='mr-2'
                  onClick={() => {
                    if (isCurrentQuizType(ComponentType.QUIZ)) {
                      setExplanation('');
                      setOptionFeedback('');
                    } else {
                      const unregister = registerSaving();
                      dispatch(addQuestionOptionFeedback({
                        questionOptionId: option.id,
                        feedback: '',
                      })).finally(() => {
                        unregister();
                        setOptionFeedback('');
                      });
                    }
                  }}
                  data-qa={dataQaQuizAttributes.questionModal.optionAddHint}
                  data-qa-id={`${dataQaQuizAttributes.questionModal.optionAddHint}_${option.id}`}
                >
                  <NvIcon icon='comments' size='small' className='text-primary' />
                </ClickableContainer>
              </NvTooltip>
              )}
              {!isDeleteDisabled && (
                <NvTooltip text={isDeleteOptionDisabled ? t.QUIZZES.ALREADY_RELEASED.OPTION_DELETE(courseAliases) : t.NOVOED.DELETE()}>
                  <ClickableContainer
                    onClick={handleDelete}
                    disabled={isDeleteOptionDisabled}
                    data-qa={dataQaQuizAttributes.questionModal.optionDelete}
                    data-qa-id={`${dataQaQuizAttributes.questionModal.optionDelete}_${option.id}`}
                  >
                    <NvIcon icon='trash' size='small' className={isDeleteOptionDisabled ? 'gray-4' : 'text-primary'} />
                  </ClickableContainer>
                </NvTooltip>
              )}
            </div>
          )}
          {!isEditMode && (
            <SelectedIndicator
              color={color}
              selected={selected()}
              questionType={currentQuestion.type}
              unselectedClassName='answer-checkbox'
            />
          )}
        </ClickableContainer>
      </div>
      {actualOptionFeedback !== null && (
        <>
          {isEditMode ? (
            <div className='d-flex align-items-center feedback-container mt-2'>
              <div className='d-flex align-items-center feedback-content-container'>
                <NvFroala
                  allowToolbar
                  value={optionFeedback}
                  editorClass='no-padding'
                  className='feedback-content'
                  minHeight={threeQuartersSpacing}
                  onBlur={() => setIsFeedbackFocused(false)}
                  onFocus={() => setIsFeedbackFocused(true)}
                  onChange={(html) => setOptionFeedback(html)}
                  placeholder={(option.isCorrect || areAllOptionsCorrect) ? t.SHARED.CORRECT() : t.SHARED.INCORRECT()}
                  dataQa={dataQaQuizAttributes.questionModal.optionHintContent}
                  dataQaId={`${dataQaQuizAttributes.questionModal.optionHintContent}_${option.id}`}
                />
              </div>
              <NvTooltip text={t.NOVOED.DELETE()}>
                <ClickableContainer
                  className='ml-3'
                  onClick={() => {
                    setSavingStatus(SavingIndicator.SAVING_STATUS);
                    clearTimeout(savingIndicatorTimeoutRef.current);

                    if (isCurrentQuizType(ComponentType.QUIZ)) {
                      setExplanation(null);
                    } else {
                      const unregister = registerSaving();
                      dispatch(deleteQuestionOptionFeedback({ questionOptionId: option.id })).then((res) => {
                        if (isEmpty(res.error)) {
                          setSavingStatus(SavingIndicator.SUCCESS_STATUS);
                        } else {
                          setSavingStatus(SavingIndicator.ERROR_STATUS);
                        }
                      }).finally(() => {
                        unregister();
                        savingIndicatorTimeoutRef.current = setTimeout(() => {
                          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
                        }, 2000);
                      });
                    }
                  }}
                  data-qa={dataQaQuizAttributes.questionModal.optionHintDelete}
                  data-qa-id={`${dataQaQuizAttributes.questionModal.optionHintDelete}_${option.id}`}
                >
                  <NvIcon icon='trash' size='small' className='text-primary' />
                </ClickableContainer>
              </NvTooltip>
            </div>
          ) : (hasStatus && (
            <div className='d-flex feedback-container mt-2 align-items-center'>
              <div className='pl-1 mr-2'>
                <NvIcon
                  size='small'
                  className='feedback-icon'
                  icon={isCorrect() ? 'success' : 'error'}
                />
              </div>
              <div className='feedback-content'>
                <ResponsivelyEmbeddedAngularHTML
                  className={`fr-element froala-style-medium ${isCorrect() ? 'text-success' : 'text-danger'}`}
                  template={!isEmpty(getOptionFeedback()) ? getOptionFeedback() : `<p>${defaultFeedback}</p>`}
                  angularServices={angularServices}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

type BigCheckboxProps = {
  index: number;
  color: string;
  checked: boolean;
  selected: boolean;
  mode: ProgressiveQuizMode;
  onChange: (newChecked) => void;
  className?: string;
  characterClassName?: string,
  optionId: number,
  circularBorder?: boolean,
};

const BigCheckbox = (props: BigCheckboxProps) => {
  const {
    mode,
    index,
    color,
    selected,
    onChange,
    className,
    checked = false,
    characterClassName,
    optionId,
    circularBorder = false,
  } = props;

  const { isAllQuestionViewQuiz } = useContext(ProgressiveQuizContext);

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const editNotFocusedBorderColor = checked ? success : gray5;

  const styles = css`
    border-radius: ${circularBorder ? '50%' : '4px'};
    width: ${largeSpacing}px;
    height: ${largeSpacing}px;
    border: 1px solid ${gray5};

    .icon {
      color: ${success};
    }

    ${mode === ProgressiveQuizMode.EDIT ? css`
      border: 1px solid ${editNotFocusedBorderColor};
    ` : css`
      ${selected && css`
        border: 1px solid ${color};
        .text-large-body {
          color: ${color};
          font-weight: bold;
        }
      `};
    `};
  `;

  return (
    <NvTooltip
      text={t.QUIZZES.MAKE_CORRECT_ANSWER()}
      enabled={mode === ProgressiveQuizMode.EDIT && !checked}
    >
      <ClickableContainer
        css={styles}
        onClick={() => onChange(!checked)}
        layoutOnly={mode !== ProgressiveQuizMode.EDIT}
        className={`align-items-center justify-content-center ${className}`}
        data-qa={dataQaQuizAttributes.questionModal.optionCorrectCheckbox}
        data-qa-id={`${dataQaQuizAttributes.questionModal.optionCorrectCheckbox}_${optionId}`}
      >
        {mode === ProgressiveQuizMode.EDIT ? (checked && (
          <NvIcon icon='check' size='smallest' className='d-flex' />
        )) : (
          <div className={`text-large-body gray-1 ${characterClassName}`}>
            {getAlphabetCharacter(index)}
          </div>
        )}
      </ClickableContainer>
    </NvTooltip>
  );
};

type SelectedIndicatorProps = {
  color: string;
  selected: boolean;
  unselectedClassName?: string;
  questionType: QuizQuestionType;
};

const SelectedIndicator = (props: SelectedIndicatorProps) => {
  const { color, selected, questionType, unselectedClassName } = props;

  const isMultipleAnswer = questionType === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;

  const commonProps = {
    width: largeSpacing,
    height: largeSpacing,
    color: selected ? color : gray5,
  };

  if (isMultipleAnswer) {
    return selected
      ? <CheckedBox {...commonProps} /> : <UncheckedBox {...commonProps} className={unselectedClassName} />;
  }

  return selected ? <SelectedRadio {...commonProps} /> : <UnselectedRadio {...commonProps} className={unselectedClassName} />;
};

export default QuizQuestionOption;
