import { RootState } from 'redux/schemas';
import { QuizQuestionType } from 'redux/schemas/models/quiz';

export const getProgressiveQuiz = (
  state: RootState,
  id: number,
) => state.models.progressiveQuizzes[id];

export const getProgressiveQuizQuestion = (
  state: RootState,
  questionId: number,
) => state.models.quizQuestions[questionId];

export const getQuizQuestions = (
  state: RootState,
  quizId: number,
  isAllQuestionViewQuiz: boolean,
) => {
  let displayIndex = 1;
  let quizQuestionsWithDisplayIndex = [];
  const quiz = isAllQuestionViewQuiz ? state.models.quizzes[quizId] : state.models.progressiveQuizzes[quizId];
  quiz.questions?.forEach((questionId) => {
    if (state.models.quizQuestions[questionId].type !== QuizQuestionType.STATEMENT) {
      quizQuestionsWithDisplayIndex = [
        ...quizQuestionsWithDisplayIndex,
        {
          ...state.models.quizQuestions[questionId],
          displayIndex,
        },
      ];
      displayIndex += 1;
    } else {
      quizQuestionsWithDisplayIndex = [
        ...quizQuestionsWithDisplayIndex,
        {
          ...state.models.quizQuestions[questionId],
          displayIndex: null,
        },
      ];
    }
  });

  return quizQuestionsWithDisplayIndex;
};

export const getQuizQuestionResponse = (
  state: RootState,
  responseId: number,
) => state.models.quizQuestionResponses[responseId] ?? null;

export const getQuizQuestionOption = (
  state: RootState,
  optionId: number,
) => state.models.quizQuestionOptions[optionId] ?? null;

export const getQuizQuestionOptions = (
  state: RootState,
  quizQuestionId: number,
) => state.models.quizQuestions[quizQuestionId]?.responseOptions.map(
  (optionId) => state.models.quizQuestionOptions[optionId],
);

export const getSubmissionQuestionResponse = (
  state: RootState,
  questionId: number,
  questionSetId: number,
) => state.models.quizzes[questionSetId]?.submission?.responses?.filter(
  (eachResponse) => eachResponse.questionId === questionId,
);

export const getQuestionAnswerFeedback = (
  state: RootState,
  questionId: number,
  questionSetId: number,
) => state.models.quizzes[questionSetId].submission?.responses?.filter(
  (eachResponse) => eachResponse.questionId === questionId,
)[0]?.feedback;
