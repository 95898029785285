import React, { MouseEventHandler } from 'react';

import { mergeRefs } from 'shared/react-utils';
import embedAngular from 'shared/embed-angular';
import { ExposedAngularServices } from 'react-app';
import { allVersionsStyles } from 'froala/helpers/nv-froala-styles';
import { makeIframesResponsive } from '../services/nv-util'; // need relative path here so storybook doesn't fail

type Props = {
  template: string,
  angularServices: ExposedAngularServices,
  className?: string,
  onCompile?: () => void,
  onClick?: MouseEventHandler<HTMLDivElement>,
  id?: string,
};

/**
 * The purpose of this component is to display user content and embeds an
 * AngularJS template string taking care of following items:
 * 1. Making iframes responsive
 * 2. Modify anchor tags in a.js
 * 3. Mentions with oe-mention.js
 * Long term, we should be handling this in  React but needs to cover all the
 * considerations that the current directive does, so this is a temporal
 * solution for previously mentioned features.
 */
const ResponsivelyEmbeddedAngularHTML = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    template,
    className,
    onCompile,
    angularServices,
    onClick,
    id,
  } = props;

  const onCompileRef = React.useRef<() => void>();
  onCompileRef.current = onCompile;

  const containerRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const processedTemplate = makeIframesResponsive(`<div>${template}</div>`).html();

    embedAngular(processedTemplate, containerRef, angularServices);

    onCompileRef.current?.();
  }, [angularServices, template]);

  return <div id={id} onClick={onClick} ref={mergeRefs(ref, containerRef)} className={className} css={allVersionsStyles} />;
});

export default ResponsivelyEmbeddedAngularHTML;
