import React, { useCallback, useRef } from 'react';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// redux
import {
  NQuizQuestion,
  QuizQuestionType,
} from 'redux/schemas/models/quiz';
import { useAppDispatch } from 'redux/store';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import useForceUpdate from 'shared/hooks/use-force-update';
import ProgressiveQuizContext, { MatchingAnswerState, QuestionContext } from 'quizzes/components/context';
import { css } from '@emotion/core';
import { gray5 } from 'styles/global_defaults/colors';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import QuizQuestionContent from './quiz-question-content';
import QuizImage from './quiz-image';
import NumberAnswerSection from './question-types/number-answer-question/number-answer-section';
import MultipleChoiceAnswerSection from './question-types/multiple-choice-question/multiple-choice-answer-section';
import ShortAnswerSection from './question-types/short-answer-question/short-answer-section';
import LongAnswerSection from './question-types/long-answer-question/long-answer-section';
import QuizHint from './quiz-hint';
import LikertScaleAnswerSection from './question-types/likert-scale-question/likert-scale-answer-section';
import MatchingQuestionSettingSection from './question-types/matching-question/matching-question-setting-section';
import MatchingQuestionAnswerSection from './question-types/matching-question/matching-question-answer-section';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};

const QuizQuestion = (props: QuizQuestionProps) => {
  const { className, currentQuestion } = props;

  const dispatch = useAppDispatch();


  const [isCurrentFeedbackShown, setIsCurrentFeedbackShown] = React.useState(false);
  const forceUpdate = useForceUpdate();

  const {
    reveal,
    closeModal,
    currentQuestionResponse,
    isAllQuestionViewQuiz,
    forwardOnQuestionsModalCloseRef,
    setForwardOnQuestionsModalCloseRef,
  } = React.useContext(ProgressiveQuizContext);

  const {
    answerState,
    setAnswerState,
  } = React.useContext(QuestionContext);

  const {
    isEditMode,
    isAnswerMode,
    isReviewMode,
    isMultipleChoiceQuestion,
    isMatchingQuestion,
  } = useQuizModeAndQuestionType();


  // This state update is to trigger the recalculation of the CTA button position when image is loaded
  const handleImageLoad = () => {
    setTimeout(() => {
      forceUpdate();
    }, 0);
  };

  React.useLayoutEffect(() => {
    if (currentQuestionResponse && !isAllQuestionViewQuiz) {
      setAnswerState(currentQuestionResponse.feedback.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
    }
  }, [currentQuestionResponse, isAllQuestionViewQuiz, setAnswerState]);

  const getQuizAnswerSection = useCallback(() => {
    switch (currentQuestion.type) {
      case QuizQuestionType.MULTIPLE_CHOICE:
      case QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return (
          <MultipleChoiceAnswerSection className={isEditMode ? '' : 'mb-5'} />
        );
      case QuizQuestionType.STATEMENT:
        return null;
      case QuizQuestionType.SHORT_TEXT_QUESTION:
      case QuizQuestionType.ALL_QUESTION_VIEW_SHORT_TEXT_QUESTION:
        return (
          <ShortAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      case QuizQuestionType.LONG_ANSWER_QUESTION:
        return (
          <LongAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      case QuizQuestionType.NUMBER_QUESTION:
        return (
          <NumberAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      case QuizQuestionType.LIKERT_SCALE_QUESTION:
        return (
          <LikertScaleAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      case QuizQuestionType.MATCHING_QUESTION:
        return (
          isEditMode ? <MatchingQuestionSettingSection /> : <MatchingQuestionAnswerSection />
        );
      default:
        return null;
    }
  }, [currentQuestion, isEditMode]);

  const isObject = (variable) => typeof variable === 'object' && variable !== null;
  const hasSelectedAtLeastOneOptions = isObject(answerState) ? Object.values(answerState).some((value) => value) : false;

  const showCorrectAnswer = isReviewMode && reveal;

  const isAtLeastOneBottomSectionElementShown = isCurrentFeedbackShown || showCorrectAnswer;
  const canShowQuizImage = currentQuestion.type !== QuizQuestionType.MATCHING_QUESTION
    && (isEditMode || !!currentQuestion.questionFileUpload?.file?.url);

  const quizQuestionRef = useRef(null);

  const styles = css`
    .bottom-section {
      ${!isEditMode && isAtLeastOneBottomSectionElementShown && css`
        border-top: 4px solid ${gray5};
      `};
    }
  `;

  const unsubmittedAnswer = useCallback(() => {
    if (currentQuestionResponse) {
      return false;
    }
    if (isMultipleChoiceQuestion) {
      return hasSelectedAtLeastOneOptions;
    }
    if (isMatchingQuestion) {
      return !isEmpty((answerState as MatchingAnswerState)?.pairs);
    }
    return !!answerState;
  }, [
    answerState,
    currentQuestionResponse,
    hasSelectedAtLeastOneOptions,
    isMultipleChoiceQuestion,
    isMatchingQuestion,
  ]);

  React.useEffect(() => {
    if (setForwardOnQuestionsModalCloseRef) {
      if (isAnswerMode && unsubmittedAnswer()) {
        const confirm = () => {
          setForwardOnQuestionsModalCloseRef(() => {}, false);
          closeModal();
        };
        if (forwardOnQuestionsModalCloseRef?.current) {
          setForwardOnQuestionsModalCloseRef(() => {
            dispatch(openConfirmationDialog({
              cancelText: t.FORM.CANCEL(),
              confirmText: t.FORM.YES_SURE(),
              onConfirm: confirm,
              title: t.QUIZZES.ARE_YOU_SURE_TO_EXIT(),
            }));
          }, true);
        }
      } else {
        setForwardOnQuestionsModalCloseRef(() => {}, false);
      }
    }
  }, [
    dispatch,
    closeModal,
    isAnswerMode,
    unsubmittedAnswer,
    forwardOnQuestionsModalCloseRef,
    setForwardOnQuestionsModalCloseRef,
  ]);

  return (
    <div ref={quizQuestionRef} css={styles} className={className}>
      <QuizQuestionContent className='mb-5' />
      {canShowQuizImage && (
        <div className='d-flex justify-content-center mb-5'>
          <QuizImage
            handleImageLoad={handleImageLoad}
            className='question-image'
          />
        </div>
      )}
      {getQuizAnswerSection()}
      {!isAllQuestionViewQuiz && (
        <QuizHint
          isCurrentFeedbackShown={isCurrentFeedbackShown}
          setIsCurrentFeedbackShown={setIsCurrentFeedbackShown}
        />
      )}
    </div>
  );
};

export default QuizQuestion;
