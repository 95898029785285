import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { contains, find, findKey, isEmpty, filter, has, extend } from 'underscore';
import Xarrow from 'react-xarrows';
import t from 'react-translate';

import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { danger, gray5, gray6, hexToRgbaString, primary, success } from 'styles/global_defaults/colors';

import { ResponseOption } from 'redux/schemas/models/quiz';

import ClickableContainer from 'components/clickable-container';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import NvTooltip from 'shared/components/nv-tooltip';
import useMatchingQuestionAnswer, { getOpionId, getSanitizedContent, MatchingQuestionAnswerContext } from 'quizzes/components/hooks/use-matching-question-answer';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import NvIcon from 'shared/components/nv-icon';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { MAX_INPUT_HEIGHT } from './question-setting-pair-response-option';

export type MatchingQuestionAnswerResponseOptionProps = {
  responseOption: ResponseOption,
};

const MAX_MATCHING_BOX_HEIGHT = 140;

const MatchingQuestionAnswerResponseOption = (props: MatchingQuestionAnswerResponseOptionProps) => {
  const { responseOption } = props;
  const [enableTooltip, setEnableTooltip] = useState(false);

  const angularServices = useContext(AngularServicesContext);
  const {
    selectedOption,
    setSelectedOption,
    hoveredId,
    setHoveredId,
    revealCorrectAnswers,
    correctAnswerPairs,
  } = useContext(MatchingQuestionAnswerContext);

  const {
    setAnswerState,
    responseOptions,
  } = useContext(QuestionContext);

  const {
    currentQuestionResponse,
    reveal,
  } = useContext(ProgressiveQuizContext);

  const { id } = responseOption;

  const {
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const {
    pairs,
    isSubmittedMode,
    checkOptionInPairs,
    getPairedOptionsId,
    checkCanDimOption,
    isQuestion,
    getArrowText,
  } = useMatchingQuestionAnswer();

  const checkContentOverflow = useCallback(() => {
    const element = document.querySelector(`[id="${getOpionId(id)}"] .option-content`);
    const contentHeight = element?.scrollHeight;

    if (contentHeight > MAX_INPUT_HEIGHT) {
      setEnableTooltip(true);
    }
  }, [id]);

  useEffect(() => {
    // Checking the the initial content overflow only after DOM has rendered
    requestAnimationFrame(checkContentOverflow);
  }, [checkContentOverflow]);

  const onHover = hoveredId === id;
  const currentIsQuestion = isQuestion(responseOption);
  const hasArrow = checkOptionInPairs(id, revealCorrectAnswers ? correctAnswerPairs : pairs);
  const isOptionDimmed = checkCanDimOption(responseOption);
  const showBackgroundColor = !isReviewMode && (onHover || (hasArrow && getPairedOptionsId(id).includes(hoveredId)));
  const isInCorrect = !revealCorrectAnswers && (isSubmittedMode || isReviewMode) && !responseOption.isCorrect;
  const showHoverArrow = (selectedOption
    && selectedOption.id !== id
    && onHover
    && selectedOption.parent !== responseOption.parent);

  const borderColor = (() => {
    if (revealCorrectAnswers) {
      return success;
    }

    if (isSubmittedMode || isReviewMode) {
      return responseOption.isCorrect ? success : danger;
    }

    return hasArrow ? primary : gray6;
  })();

  const dotColor = (() => {
    if (revealCorrectAnswers) {
      return success;
    }

    if (isSubmittedMode || isReviewMode) {
      return responseOption.isCorrect ? success : danger;
    }

    return (selectedOption?.id === id || hasArrow) ? primary : gray5;
  })();

  const styles = css`
    &.matching-box {
      position: relative;
      width: 320px;
      border: ${isInCorrect ? 'dashed' : 'solid'} 1px ${borderColor};
      border-radius: ${halfSpacing}px;
      text-align: center;
      cursor: pointer;
      height: 100%;
      max-height: ${MAX_MATCHING_BOX_HEIGHT}px;
      box-sizing: border-box;
      ${!isSubmittedMode && !hasArrow && css`
        box-shadow: 0px 4px 10px 0px #0000001A;
      `}

      .option-content {
        max-height: 100%;
        overflow: hidden;
      }

      .dot {
        position: absolute;
        width: ${standardSpacing}px;
        height: ${standardSpacing}px;
        border-radius: 50%;
        background-color: ${dotColor};
        z-index: 1;
      }

      .embed-wrapper {
        padding: ${standardSpacing}px;
      }

      ${selectedOption?.id === id ? css`
        border: solid 3px ${primary};
        // Reducing the padding by 2px to equalize the height with the increased
        // 2px border height. This prevents any height changes and eliminates
        // the jumping effect during box selection.
        .embed-wrapper {
          padding: ${standardSpacing - 2}px !important;
        }

        // Keeping the pair boxes in the same positions for both LTR and RTL
        // languages, thus avoiding flipping.
        .dot-right {
          /*! @noflip */
          right: -12px;
        }
        .dot-left {
          /*! @noflip */
          left: -12px;
        }
      ` : css`
        .dot-right {
          /*! @noflip */
          right: -${halfSpacing}px !important;
        }
        .dot-left {
          /*! @noflip */
          left: -${halfSpacing}px !important;
        }
      `}

      ${isOptionDimmed && css`
        opacity: 60%;
      `};

      ${!isOptionDimmed && showBackgroundColor && css`
        background-color: ${hexToRgbaString(primary, 0.05)};
      `}

      .gradiant-box {
        position: absolute;
        width: 100%;
        height: ${doubleSpacing}px;
        bottom: ${standardSpacing}px;
        background: linear-gradient(360deg, ${(!isOptionDimmed && showBackgroundColor) ? '#F3F8FE' : '#FFFFFF'} 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
        pointer-events: none;
      }
    }
  `;

  const onOptionClick = () => {
    if (selectedOption?.id === id) {
      return setSelectedOption(null);
    }

    let newPairs = pairs;

    // Deleting the arrow if one already exists
    if (hasArrow) {
      const existingKey = currentIsQuestion ? id : getPairedOptionsId(id)?.[0];

      newPairs = filter(pairs, pair => !has(pair, existingKey));

      if (existingKey) {
        setAnswerState({
          pairs: newPairs,
        });
      }
    }

    if (!isEmpty(selectedOption)
      && !isOptionDimmed
      && selectedOption.parent !== responseOption.parent
    ) {
      const questionId = currentIsQuestion ? id : selectedOption.id;
      const optionId = currentIsQuestion ? selectedOption.id : id;
      newPairs.push({ [questionId]: [optionId] });

      setAnswerState({
        pairs: newPairs,
      });

      setSelectedOption(null);
    } else {
      setSelectedOption(responseOption);
    }

    return null;
  };

  const getCorrectPairId = useCallback((optionId) => {
    const transformedPairs = extend({}, ...correctAnswerPairs);

    if (!isEmpty(transformedPairs[optionId])) {
      return transformedPairs[optionId][0];
    }

    return +findKey(transformedPairs, (pair) => contains(pair, optionId));
  }, [correctAnswerPairs]);

  const getPairedOptionContent = useCallback((optionId: number, isReview = false) => {
    const pairedId = isReview ? getCorrectPairId(optionId) : getPairedOptionsId(optionId)?.[0];
    const pairedOption = find(responseOptions, (option) => option.id === pairedId);

    if (isEmpty(pairedOption)) {
      return '';
    }

    return getSanitizedContent(pairedOption.optionContent);
  }, [getCorrectPairId, getPairedOptionsId, responseOptions]);

  const getOptionAriaLabel = useCallback(() => {
    let label = t.QUIZZES.MATCHING_QUESTION.OPTION_CONTENT_ARIA_LABEL(getSanitizedContent(responseOption.optionContent), responseOption.parent.toString());
    if (isSubmittedMode || isReviewMode) {
      label += t.QUIZZES.MATCHING_QUESTION.REVEAL_OPTION_ARIA_LABEL(getPairedOptionContent(id), responseOption.isCorrect.toString());

      if (reveal && !responseOption.isCorrect) {
        label += t.QUIZZES.MATCHING_QUESTION.CORRECT_LINK_ARIA_LABEL(getPairedOptionContent(id, true));
      }
    } else {
      if (hasArrow) {
        label += t.QUIZZES.MATCHING_QUESTION.LINKED_OPTION_ARIA_LABEL(getPairedOptionContent(id));
      }

      if (showHoverArrow) {
        label += t.QUIZZES.MATCHING_QUESTION.SELECT_TO_LINK_ARIA_LABEL(getSanitizedContent(selectedOption.optionContent));
      }
    }

    return label;
  }, [getPairedOptionContent, hasArrow, isReviewMode, isSubmittedMode, reveal,
    id, responseOption?.isCorrect, responseOption?.optionContent,
    responseOption?.parent, selectedOption?.optionContent, showHoverArrow,
  ]);

  const onFocus = () => !isSubmittedMode && !isOptionDimmed && setHoveredId(id);
  const onBlur = () => (!isSubmittedMode && !isOptionDimmed) && setHoveredId(null);

  return (
    <React.Fragment>
      <div className='d-flex flex-column'>
        <ClickableContainer
          css={styles}
          className='matching-box d-flex justify-content-center align-items-center'
          id={getOpionId(id)}
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={() => !(isSubmittedMode || isReviewMode) && onOptionClick()}
          aria-label={getOptionAriaLabel()}
          aria-selected={selectedOption?.id === id || hasArrow}
        >
          <React.Fragment>
            <NvTooltip
              text={getSanitizedContent(responseOption.optionContent)}
              enabled={enableTooltip}
            >
              <div className='embed-wrapper d-flex align-items-center justify-content-center w-100 h-100'>
                <ResponsivelyEmbeddedAngularHTML
                  angularServices={angularServices}
                  template={responseOption.optionContent}
                  className='option-content'
                />
              </div>
            </NvTooltip>
            {((onHover && !isReviewMode) || selectedOption?.id === id || hasArrow) && (
              <div className={`dot dot-${currentIsQuestion ? 'right' : 'left'} d-flex align-items-center justify-content-center text-small bold text-white`}>
                {getArrowText(responseOption, revealCorrectAnswers ? correctAnswerPairs : pairs)}
              </div>
            )}
            {enableTooltip && (<div className='gradiant-box' />)}
          </React.Fragment>
        </ClickableContainer>
        {(currentQuestionResponse?.feedback || reveal) && (
          <div className={`d-flex mt-1 align-items-center text-${(responseOption.isCorrect || revealCorrectAnswers) ? 'success' : 'danger'}`}>
            <div className='pl-1 mr-2'>
              <NvIcon
                size='small'
                className='feedback-icon'
                icon={(responseOption.isCorrect || revealCorrectAnswers) ? 'success' : 'error'}
              />
            </div>
            <div className='text-large-body'>
              {t.SHARED[(responseOption.isCorrect || revealCorrectAnswers) ? 'CORRECT' : 'INCORRECT']() }
            </div>
          </div>
        )}
      </div>

      {showHoverArrow && (
        <Xarrow
          start={getOpionId(selectedOption.id)}
          end={getOpionId(id)}
          startAnchor={isQuestion(selectedOption) ? 'right' : 'left'}
          endAnchor={isQuestion(selectedOption) ? 'left' : 'right'}
          strokeWidth={2}
          color={primary}
          showHead={false}
          showTail={false}
          path='straight'
          dashness
        />
      )}
    </React.Fragment>
  );
};

export default MatchingQuestionAnswerResponseOption;
