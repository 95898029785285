import React, { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';
import { css } from '@emotion/core';
import t from 'react-translate';

import NvIcon from 'shared/components/nv-icon';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import { NQuizQuestion, QuizQuestionType } from 'redux/schemas/models/quiz';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';

type ViewAnswerFeedBackProps = {
  currentQuestion: NQuizQuestion;
};

const ViewAnswerFeedBack = (props: ViewAnswerFeedBackProps) => {
  const {
    currentQuestion,
  } = props;

  const {
    currentQuestionResponse,
  } = React.useContext(ProgressiveQuizContext);

  const {
    getFeedbackElementId,
  } = useQuizModeAndQuestionType();

  const {
    responseOptions,
  } = useContext(QuestionContext);

  const angularServices = React.useContext(AngularServicesContext);

  const {
    explanation,
    secondaryExplanation,
    topOfRange,
    bottomOfRange,
  } = responseOptions[0];

  const styles = css`
    .fr-element {
      p {
        margin-bottom: 0px;
      }
    }
  `;
  const getFeedback = () => {
    switch (currentQuestion.type) {
      case QuizQuestionType.LONG_ANSWER_QUESTION:
        return (!isEmpty(explanation))
          ? explanation
          : `<p>${t.QUIZZES.LONG_ANSWER_QUESTION.DEFAULT_FEEDBACK()}</p>`;
      case QuizQuestionType.LIKERT_SCALE_QUESTION:
        /**
         * For Likert scale if an answer and correct answer feedback is not set,
         * show feedback as 'All answers are correct.'
        */
        if (currentQuestionResponse.isCorrect
          && isEmpty(explanation)
          && isEmpty(topOfRange)
          && isEmpty(bottomOfRange)
        ) {
          return `<p>${t.QUIZZES.LIKERT_SCALE_QUESTION.DEFAULT_FEEDBACK()}</p>`;
        }

        if (currentQuestionResponse.isCorrect) {
          return (!isEmpty(explanation))
            ? explanation
            : `<p>${t.SHARED.CORRECT()}</p>`;
        }
        return (!isEmpty(secondaryExplanation))
          ? secondaryExplanation
          : `<p>${t.SHARED.INCORRECT()}</p>`;
      default:
        // Answer feedback for other question types
        if (currentQuestionResponse.isCorrect) {
          return (!isEmpty(explanation))
            ? explanation
            : `<p>${t.SHARED.CORRECT()}</p>`;
        }
        return (!isEmpty(secondaryExplanation))
          ? secondaryExplanation
          : `<p>${t.SHARED.INCORRECT()}</p>`;
    }
  };

  return (
    <div id={getFeedbackElementId(currentQuestion.id)} css={styles} className='d-flex mt-2'>
      <NvIcon
        size='small'
        className={`${currentQuestionResponse.isCorrect ? 'text-success' : 'text-danger'} d-flex align-items-center px-1`}
        icon={`${currentQuestionResponse.isCorrect ? 'success' : 'error'}`}
      />
      <ResponsivelyEmbeddedAngularHTML
        className={`ml-2 w-100 fr-element froala-style-medium ${currentQuestionResponse.isCorrect ? 'text-success' : 'text-danger'} mb-0`}
        template={getFeedback()}
        angularServices={angularServices}
      />
    </div>
  );
};

export default ViewAnswerFeedBack;
