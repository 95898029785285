import { MatchingAnswerState } from 'quizzes/components/context';
import { PointsConfiguration } from './points-configuration';
import { MinimalActivity, ActivityType, ActivityProgress } from './activity';
import { Communication } from './course-communication';
import { Normalized } from '../normalized';
import { UploadedFile } from './file-uploads';
import {
  ProgressiveQuiz,
} from './progressive-quiz';

export interface MinimalQuiz extends MinimalActivity {
  type: ActivityType.QUIZ
}

export type TimelineQuiz = {
  id: number;
  title: string;
  ownerTitle?: string;
  titleIndex?: number;
  isRequiredForCompletion: boolean;
  expirationDate: string | null;
  releaseDate: string;
  answerableQuestionsCount: number;
  pointsConfiguration: PointsConfiguration;
  activityType: string;
  totalPoints: number[];
  pointsReceived: number;
  releaseResultsAt?: string;
  timeLimit?: number;
  timeInterval?: string;
  duration?: number;
  timeRemaining?: number;
  expired?: boolean;
  catalogId: string;
  courseCatalogId: string,
  courseId: number;
  lecturePageId: number;
  lectureComponentId: number;

  communications?: Communication[],
  communicationsCount?: number,

  // These 4 props may only exist on the lecture page component
  // TOOD: Move to a new type?
  hardDeadline: boolean,
  graded: boolean,
  maximumAttempts: number,
  isTodo: false,
  hardDeadlinePassed?: boolean;

  hasStructuralIssues?: boolean;
  questionsAttributes?: QuizAIQuestion[];
} & MinimalQuiz;

export type NTimelineQuiz = Normalized<TimelineQuiz, 'communications'>;

export interface TimelineQuizzesNormalized { [id: string]: NTimelineQuiz }

export interface SubmissionResponse {
  id: number
  questionId: number
  questionSetSubmissionId: null
  response: number | number[] | string
  otherResponse: null
  submissionRevisionId: number
  childrenQuestionResponses: null
  isCorrect?: boolean
  numberOfAttempts?: number
  numberOfCorrectOptions?: number
  attemptFeedback: null
  feedback: ResponseOption[]
}

export enum QuizQuestionType {
  MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'MultipleChoiceMultipleAnswerQuestion',
  STATEMENT = 'ReadOnlyQuestion',
  SHORT_TEXT_QUESTION = 'MultipleShortAnswerQuestion',
  ALL_QUESTION_VIEW_SHORT_TEXT_QUESTION = 'ShortTextQuestion',
  LONG_ANSWER_QUESTION = 'RichTextQuestion',
  NUMBER_QUESTION = 'NumberQuestion',
  LIKERT_SCALE_QUESTION = 'LikertScaleQuestion',
  MATCHING_QUESTION = 'MatchingQuestion',
}

export interface SubmissionQuiz {
  id: number
  title: 'Quiz'
  graded: boolean
  answerableQuestionsCount: number
  activityType: 'QuestionSet'
  progress: 'in_progress'
  correctAnswersCount: number
}

export interface UnsubmittedSubmission {
  id: number
  exerciseId: number
  type: 'questionsetsubmission'
  lastSavedAt: string
  owner: null
  questionSet: null
  exercise: null
  score: number
  maxScore: number
  numberOfAttempts: number
  editable: boolean
  pointsReceived: null
  leaderboardPoints: number
  pointsReceivedFromPreviousAttempt: number
  leaderboardRank: null
  priorLeaderboardRank: null
  startedExamAt: null
  timeRemaining: number
  releaseResultsAt: null
  questionsSubmitted: boolean
  windowId: number
  questions: QuizQuestion[]
  responses: SubmissionResponse[]
  quiz: SubmissionQuiz
}

export enum AiQuestionType {
  MULTIPLE_CHOICE_SINGLE_ANSWER = 'multiple_choice_single_answer',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'multiple_choice_multiple_answer',
  TRUE_OR_FALSE = 'true_or_false',
  FILL_BLANKS = 'fill_blanks',
}

export type QuizAIQuestion = {
  id: string;
  questionText: string;
  type: string;
  responseOptionsAttributes: QuizAIQuestionOption[];
};

type QuizAIQuestionOption = {
  optionContent: string,
  explanation: string,
  isCorrect: boolean,
};

export type QuizQuestion = {
  id: number;
  isRequired: boolean;
  questionText: string;
  questionIndex: number;
  questionSetId: number;
  type: QuizQuestionType;
  questionFileUpload?: QuestionFileUpload;
  responses: null | QuizQuestionResponse;
  previousAttemptResponses?: number | null;
  // Admin will only get this property
  correctAnswersCount?: number;
  totalQuestionAttempts: number;
  completedQuestionAttempts: number;
  // Admin will only get this property
  attemptFeedbacks?: QuizQuestionFeedback[];
  // Learner will only get this property
  attemptFeedback?: QuizQuestionFeedback;
  responseOptions: ResponseOption[];
  retry?: number;
  displayIndex?: number | null;
  second?: null,
  childrenQuestions?: null,
  numRows?: null,
  numColumns?: null,
  horizontalAxisLabel?: string,
  verticalAxisLabel?: string,
  leftLimit?: number,
  rightLimit?: number,
};

export type QuizQuestionResponse = {
  id: number;
  isCorrect: false;
  questionId: number;
  numberOfAttempts: number;
  response: QuizResponse;
  numberOfCorrectOptions: number;
  attemptFeedback: QuizQuestionFeedback;
  feedback: ResponseOption[];
  // Not using these for now:
  // questionSubmissionRevisionId: number;
};

export type NQuizQuestionResponse = Normalized<QuizQuestionResponse, 'feedback' | 'attemptFeedback'>;

export type QuizQuestionResponseNormalized = Record<number, NQuizQuestionResponse>;

export type QuestionFileUpload = {
  file: UploadedFile | null;
};

export type NQuizQuestion = Normalized<QuizQuestion, 'responseOptions' | 'attemptFeedbacks' | 'responses'>;

export type QuizQuestionsNormalized = Record<number, NQuizQuestion>;

export type QuizSubmission = {
  id: number;
  leaderboardRank: number;
  leaderboardPoints: number;
  type: 'questionsetsubmission';
  pointsReceived: number | null;
  responses: QuizQuestionResponse[];
  priorLeaderboardRank: number | null;
  questions: QuizQuestion[];
  score: number;
  quiz: {
    id: number;
    progress: ActivityProgress;
    correctAnswersCount: number;
  },
};

export type NQuizSubmission = Normalized<QuizSubmission, 'responses' | 'questions'>;

export type QuizSubmissionsNormalized = Record<number, NQuizSubmission>;

export type QuizQuestionSubmission = {
  id: number;
  questionSetId: number;
  questions: QuizQuestion;
  type: 'questionsubmission';
  questionSet: ProgressiveQuiz;
};

export type NQuizQuestionSubmission = Normalized<QuizQuestionSubmission, 'questions' | 'questionSet'>;

export type QuizQuestionSubmissionNormalized = Record<number, NQuizQuestionSubmission>;

export type ResponseOption = {
  id: number;
  index: number;
  questionId: number;
  isCorrect: boolean;
  optionContent: string;
  optionFeedback: null | string;
  explanation: string;
  secondaryExplanation: string;
  bottomOfRange: string,
  topOfRange: string,
  readOnly?: false,
  votesCount?: null,
  voters?: null,
  read_only?: false,

  // Matching question fields
  parent: boolean;
  associationId: number

  // These properties seem to be from quiz 1.0 so not using them for now:
  // 'votes_count': null,
  // 'voters': null,
};

export type ResponseOptionsNormalized = Record<number, ResponseOption>;

export type QuizQuestionFeedback = {
  id: number;
  index: number;
  feedbackText: string;
};

export type QuizQuestionFeedbackNormalized = Record<number, QuizQuestionFeedback>;

export type MultiChoiceMultiAnswerQuestionResponse = number[];
type MultipleChoiceQuestionResponse = number | MultiChoiceMultiAnswerQuestionResponse;
type ShortAnswerQuestionResponse = string;
type NumberAnswerQuestionResponse = number;
export type MatchingQuestionResponse = MatchingAnswerState['pairs'];

export type QuizResponse =
  MultipleChoiceQuestionResponse
  | ShortAnswerQuestionResponse
  | NumberAnswerQuestionResponse
  | MatchingQuestionResponse;
