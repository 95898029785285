import { css } from '@emotion/core';
import React from 'react';
import t from 'react-translate';
import { isEmpty } from 'underscore';

// redux
import { useAppDispatch } from 'redux/store';
import { editQuizQuestion } from 'redux/actions/quizzes';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import useFocusEvent from 'shared/hooks/use-focus-event';
import useUploadFile from 'shared/hooks/use-upload-file';
import NvFilePicker from 'shared/components/nv-filepicker';
import ClickableContainer from 'components/clickable-container';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import NvFileUploadProgress from 'shared/components/nv-file-upload-progress';
import ValidationErrorMessage from 'shared/components/validation-error-message';
import { halfSpacing, longBar, standardSpacing } from 'styles/global_defaults/scaffolding';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import {
  black,
  danger,
  gray1,
  gray3,
  gray4,
  hexToRgbaString,
  primary,
} from 'styles/global_defaults/colors';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import NvUploadedFileContent from 'shared/components/nv-uploaded-file/nv-uploaded-file-content';
import useUploadedFile from 'shared/components/nv-uploaded-file/hooks/use-uploaded-file';
import { probablySupportsVideoType } from 'recording/services/media-visualizer-helper';
import { config } from '../../../config/config.json';

const ALLOWED_FILE_TYPES = [
  ...config.files.allImageExtensions,
  ...config.files.allAudioExtensions,
  ...config.files.allVideoExtensions,
  ...config.files.rte.spreadsheets.extensions,
  ...config.files.rte.documents.extensions,
  ...config.files.rte.presentations.extensions,
];
const MAX_FILE_SIZE = 52428800;
const MAX_PREVIEW_FILE_SIZE = 10000000;

type Props = {
  className?: string;
  handleImageLoad?: Function;
};

const QuizImage = (props: Props) => {
  const { className, handleImageLoad } = props;

  const dispatch = useAppDispatch();
  const imageContainerRef = React.useRef();
  const [isFocused, setIsFocused] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
  } = useQuizModeAndQuestionType();
  const { currentQuestion } = React.useContext(QuestionContext);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [showFullScreen, setShowFullScreen] = React.useState(false);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const { uploadFiles, isUploading, hasUploadPercentage, filesUploading, abortUpload } = useUploadFile();
  const { getNormalizeFileType } = useUploadedFile();

  const file = currentQuestion?.questionFileUpload?.file;
  const canProbablyPreview = !file?.url || probablySupportsVideoType(file?.mimeType || file?.type);
  const normalizedFileType = file?.type ? getNormalizeFileType(file?.type, file?.name) : null;

  const getUrl = () => {
    if (!canProbablyPreview && normalizedFileType === 'video') {
      if (file?.mediumQualityUrl) {
        return file?.mediumQualityUrl;
      }
      if (file?.lowQualityUrl) {
        return file?.lowQualityUrl;
      }
      return file?.url;
    }
    return file?.url;
  };

  const url = getUrl();

  const uploadedFile = {
    name: file?.name,
    uniqueId: file?.uniqueId,
    url,
    downloadUrl: url,
    size: file?.size,
    boxDocumentId: file?.boxDocumentId,
    type: file?.type,
    hasBeenTranscoded: file?.hasBeenTranscoded,
  };

  const isDocument = !(normalizedFileType === 'image' || normalizedFileType === 'audio' || normalizedFileType === 'video');
  const isImage = normalizedFileType === 'image';

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  React.useEffect(() => {
    if (!url) {
      setIsImageLoaded(false);
    }
  }, [url]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isUploading) {
      return registerSaving();
    }
  }, [isUploading, registerSaving]);

  useFocusEvent(imageContainerRef, 'focus', () => setIsFocused(true), []);

  useFocusEvent(imageContainerRef, 'blur', () => setIsFocused(false), []);

  const styles = css`
    ${url && css`
      width: 100%;
      height: 100%;
    `};

    .uploaded-file {
      .image {
        display: flex;
        justify-content: center;
      }
    }

    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .separator {
        width: 1px;
        background-color: ${gray3};
        height: ${standardSpacing}px;
      }

      .image-option:hover {
        color: ${black};
      }
      .delete-option:hover {
        color: ${danger};
      }
    }
    .no-preview {
      height: ${longBar}px;
    }
    .delete-icon-container {
      width: max-content;
    }
    .deletion-confirmation {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${hexToRgbaString(gray1, 0.95)};
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .deletion-confirmation-content {
        .confirmation-text {
          margin-bottom: ${halfSpacing}px;
        }
      }
      .btn-danger {
        margin-left: ${halfSpacing}px;
      }
    }
    .file {
      min-height: 75px;
    }
  `;

  const modalStyles = css`
    .uploaded-file {
      .image {
        display: flex;
        justify-content: center;
        img {
          width: 100%
        }
      }
    }
  `;

  const handleImagePickerChange = async (files: File[]) => {
    const uploadedFiles = await uploadFiles(files, S3NameSpaces.QUIZ_QUESTIONS);
    const { name, size, type, uniqueId } = uploadedFiles[0] || {};

    // Handling upload cancellation
    if (!uniqueId) {
      return;
    }

    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(editQuizQuestion({
      id: currentQuestion.id,
      patch: {
        fileName: name,
        fileSize: size,
        fileContentType: type,
        fileUniqueId: uniqueId,
      },
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const handleDelete = () => {
    setShowError(false);

    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(editQuizQuestion({ id: currentQuestion.id,
      patch: {
        fileName: null,
        fileSize: null,
        fileContentType: null,
        fileUniqueId: null,
      },
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const wrapWithPicker = (children, extraCondition = true, pickerStyle?, wrapperStyle?) => (
    <NvPopover
      placement='top'
      css={wrapperStyle}
      show={showError && extraCondition}
      rootClose
      content={(
        <ValidationErrorMessage
          header={t.FORM.WARNING()}
          headerClassName='semi-bold'
          text={t.FILE_UPLOAD.FILE_EXCEEDS('50MB')}
        />
      )}
    >
      <NvFilePicker
        multiple={false}
        css={pickerStyle}
        maxSize={MAX_FILE_SIZE}
        accept={ALLOWED_FILE_TYPES}
        onChange={handleImagePickerChange}
        onClick={() => setShowError(false)}
        className={`d-flex align-items-center justify-content-center ${className}`}
        data-qa={dataQaQuizAttributes.questionModal.addQuestionFile}
        onSelectError={(e) => {
          const exceeds = e.some(({ errors }) => errors.some(({ code }) => code === 'file-too-large'));
          if (exceeds) {
            setShowError(true);
            dispatch(addAlertMessage({
              header: t.FORM.OOPS(),
              type: AlertMessageType.ERROR,
              message: t.FILE_UPLOAD.SIZE_EXCEEDED_ALERT('50MB'),
              iconClass: 'icon-warning',
            }));
          }
        }}
      >
        {children}
      </NvFilePicker>
    </NvPopover>
  );

  if (isUploading) {
    return (
      <NvFileUploadProgress
        abortUpload={abortUpload}
        css={css` min-width: 720px `}
        uploadInProgress={isUploading}
        fileUploading={filesUploading[0]}
        hasUploadPercentage={hasUploadPercentage}
      />
    );
  }

  return (
    url ? (
      <div css={styles}>
        {isEditMode && (
          <ClickableContainer
            onClick={() => setShowDeleteConfirmation(true)}
            className='d-flex text-primary mb-1 cursor-pointer delete-icon-container'
            data-qa={dataQaQuizAttributes.questionModal.showDeleteOverlay}
          >
            <NvIcon size='smallest d-flex align-items-center' icon='trash' />
            <p className='mb-0 text-small semi-bold ml-1'>{t.NOVOED.DELETE()}</p>
          </ClickableContainer>
        )}
        <div
          ref={imageContainerRef}
          className={`position-relative d-flex align-items-center justify-content-center ${className}`}
        >
          <NvModal
            doubleModal
            type={ModalType.FULL}
            show={showFullScreen}
            onClose={() => setShowFullScreen(false)}
            bodyClassName='uploaded-file'
            modalStyles={modalStyles}
          // NOTE: Using this translation to not create a new one
            header={t.LECTURE_PAGES.COMPONENTS.CONTENT_TEMPLATES.LABELS.FULL_SCREEN()}
            body={(
              <div className='w-100 h-100'>
                <NvUploadedFileContent isOpenedInModal file={uploadedFile} />
              </div>
          )}
          />
          {uploadedFile.boxDocumentId || !isDocument ? (
            <ClickableContainer
              className='file w-100 h-100 align-items-center'
              layoutOnly={isEditMode || !isImage}
              onClick={() => setShowFullScreen(true)}
              data-qa={dataQaQuizAttributes.questionModal.uploadedFile}
            >
              <div className='w-100 h-100 uploaded-file'>
                <NvUploadedFileContent isOpenedInModal file={uploadedFile} />
              </div>
            </ClickableContainer>
          ) : (
            <ClickableContainer
              layoutOnly
              className='no-preview w-100 d-flex bg-gray-6 align-items-center justify-content-center'
            >
              <div className='d-flex flex-column align-items-center'>
                <p className='text-large-regular text-gray-3 mb-0'>
                  {t.VIDEO.PREVIEW_NOT_AVAILABLE()}
                </p>
                <a
                  href={uploadedFile.url}
                  target='_blank'
                  rel='noreferrer'
                  download
                  className='cursor-pointer'
                  data-qa={dataQaQuizAttributes.questionModal.downloadFile}
                >
                  <p className='text-primary bold text-small mt-1 mb-0'>
                    {t.FILE_UPLOAD.DOWNLOAD_TO_VIEW()}
                  </p>
                </a>

              </div>
            </ClickableContainer>
          )}
          {showDeleteConfirmation && (
            <div className='h-100 w-100 position-absolute d-flex align-items-center justify-content-center overlay gray-3'>
              <div className='deletion-confirmation'>
                <div className='deletion-confirmation-content'>
                  <div className='confirmation-text'>{t.FILE_UPLOAD.CONFIRM_DELETE()}</div>
                  <button
                    type='button'
                    className='btn btn-default btn-sm dark-theme'
                    onClick={() => setShowDeleteConfirmation(false)}
                    data-qa={dataQaQuizAttributes.questionModal.cancelFileDelete}
                  >{t.FORM.CANCEL()}
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger btn-sm dark-theme'
                    onClick={() => { setShowDeleteConfirmation(false); handleDelete(); }}
                    data-qa={dataQaQuizAttributes.questionModal.confirmFileDelete}
                  >{t.FORM.DELETE()}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (isEditMode && wrapWithPicker(
      <div className='d-flex flex-column align-items-center'>
        <NvIcon icon='upload' size='large' className='mr-4 upload-icon' />
        <div className='text-align-center mt-2 px-5'>
          <div className='text-regular gray-2 font-weight-bolder'>{t.FILE_UPLOAD.UPLOAD_DOCUMENT()}</div>
          <div className='text-small gray-2'>
            {t.FILE_UPLOAD.UPLOAD_MULTI_MEDIA_UNDER_A_SIZE('50MB')}
          </div>
        </div>
      </div>,
      true,
      pickerStyles,
      popoverWrapperStyle,
    ))
  );
};

const pickerStyles = css`
  border: 1px dashed ${gray4};
  height: ${longBar}px;
  .upload-icon {
    color: ${gray3};
  }
  &:hover {
    .upload-icon {
      color: ${primary};
    }
  }
`;

const popoverWrapperStyle = css`
  width: 100%;
`;

export default QuizImage;
