import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/core';

// redux
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { NQuizQuestion } from 'redux/schemas/models/quiz';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { wrapThunkAction } from 'redux/utils';
import { editNumberAnswer } from 'redux/actions/quizzes';

import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import validationConstants from 'shared/services/constants-shared';
import { gray2, gray5, primary, white } from 'styles/global_defaults/colors';
import { quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { config } from '../../../../../config/pendo.config.json';

type NumberAnswerSettingInputProps = {
  setTriggerShowFeedback: Function
  isAnswerInRange: boolean
  setIsAnswerInRange: Function
  topOfRange: string
  bottomOfRange: string
  setBottomOfRange: Function
  setTopOfRange: Function
  currentQuestion: NQuizQuestion
  showFeedbackIcon: boolean
  isBottomRange: boolean
};

const NumberAnswerSettingInput = (props: NumberAnswerSettingInputProps) => {
  const {
    setTriggerShowFeedback,
    isAnswerInRange,
    setIsAnswerInRange,
    topOfRange,
    bottomOfRange,
    setTopOfRange,
    setBottomOfRange,
    currentQuestion,
    showFeedbackIcon,
    isBottomRange,
  } = props;

  const [focusInput, setFocusInput] = React.useState(false);
  const inputRef = React.useRef(null);

  const styles = css`
    &.number-input-container {
      border: 1px solid ${focusInput ? primary : gray5};
      border-radius: ${quarterSpacing}px;
      height: ${tripleSpacing}px;
      width: 45%;
      .input-style {
        font-size: 16px;
        border: none;
        background-color: ${white};
        ::placeholder {
          color: ${gray2};
        }
      }
      &:hover .feedback-icon-container {
        opacity: 1 !important;
      }
      .feedback-icon-container {
        opacity: 0;
      }
    }
    .dash-component {
      width: 10%;
    }
  `;
  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);

  const handleShowFeedBackInput = (e) => {
    e.stopPropagation();
    setTriggerShowFeedback(true);
  };

  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    isAllQuestionViewQuiz,
  } = React.useContext(ProgressiveQuizContext);

  const {
    responseOptions,
  } = React.useContext(QuestionContext);

  const {
    isLikertAnswerQuestion,
  } = useQuizModeAndQuestionType();

  const dataQaQuizAttributes = isAllQuestionViewQuiz
    ? config.pendo.activities.quiz
    : config.pendo.activities.progressiveQuiz;

  const handleAddRange = (e) => {
    e.stopPropagation();
    handleEditNumberAnswer(bottomOfRange, '');
    setTopOfRange('');
    setIsAnswerInRange(true);
  };

  const handleEditNumberAnswer = (bottomOfRangeParam: string, topOfRangeParam: string) => {
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    wrapThunkAction(dispatch(editNumberAnswer(
      {
        questionOptionId: responseOptions[0].id,
        bottomOfRange: bottomOfRangeParam,
        topOfRange: topOfRangeParam,
      },
    ))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const removeRange = (isRemovingBottomRange: boolean) => {
    if (isRemovingBottomRange) {
      handleEditNumberAnswer(topOfRange, topOfRange);
      setBottomOfRange(topOfRange);
    } else {
      handleEditNumberAnswer(bottomOfRange, bottomOfRange);
      setTopOfRange(bottomOfRange);
    }
    setIsAnswerInRange(false);
  };
  const isNumberValid = (value: string) => /^(?:(?=.*\d)(?!.*\.$).*)?$/.test(value);

  const handleChange = (e) => {
    const { value } = e.target;
    const regex = validationConstants.NUMBER_ANSWER_REGEX;
    if (regex.test(value)) {
      if (isBottomRange) {
        setBottomOfRange(value);
        if (!isNumberValid(value)) {
          return;
        }
        if (!isAnswerInRange) {
          handleEditNumberAnswer(value, value);
          return;
        }
        handleEditNumberAnswer(value, topOfRange);
      } else {
        setTopOfRange(value);
        if (!isNumberValid(value)) {
          return;
        }
        handleEditNumberAnswer(bottomOfRange, value);
      }
    }
  };

  const getPlaceHolder = () => {
    // Likert question type - using same placeholder for both topRange and bottomRange
    if (isLikertAnswerQuestion) {
      return t.QUIZZES.LIKERT_SCALE_QUESTION.OPTIONAL();
    }

    // For number answer question type
    if (isBottomRange) {
      if (isAnswerInRange) {
        return t.QUIZZES.NUMBER_ANSWER_QUESTION.LOW_NUMBER();
      }
      return '0';
    }
    return t.QUIZZES.NUMBER_ANSWER_QUESTION.HIGH_NUMBER();
  };

  const getIcons = () => {
    if (isBottomRange) {
      if (!isAnswerInRange) {
        return (
          <NvTooltip text={t.QUIZZES.NUMBER_ANSWER_QUESTION.ADD_CORRECT_RANGE()}>
            <NvIcon
              onClick={handleAddRange}
              icon='add'
              size='small'
              className='d-flex align-items-center ml-3 text-primary'
              data-qa={dataQaQuizAttributes.questionModal.numberAnswerQuestion.addRange}
            />
          </NvTooltip>
        );
      }
      return (
        <NvIcon
          onClick={() => removeRange(true)}
          icon='trash'
          size='small'
          className='d-flex align-items-center ml-3 text-primary'
          data-qa={dataQaQuizAttributes.questionModal.numberAnswerQuestion.deleteRange}
        />
      );
    }
    return (
      <NvIcon
        onClick={() => removeRange(false)}
        icon='trash'
        size='small'
        className='d-flex align-items-center ml-3 text-primary'
        data-qa={dataQaQuizAttributes.questionModal.numberAnswerQuestion.deleteRange}
      />
    );
  };

  return (
    <div css={styles} onClick={() => inputRef.current.focus()} className='number-input-container d-flex align-items-center pr-3'>
      <NvTextInput
        ref={inputRef}
        className='w-75'
        inputClassName='input-style'
        value={isBottomRange ? bottomOfRange : topOfRange}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        placeholder={getPlaceHolder()}
        onChange={(e) => { handleChange(e); }}
        data-qa={dataQaQuizAttributes.questionModal.numberAnswerQuestion.editNumberAnswer}
      />
      <div className='feedback-icon-container d-flex justify-content-end w-25'>
        {showFeedbackIcon && (
        <NvTooltip text={t.QUIZZES.FEEDBACK_DEFAULT_INFO(courseAliases)}>
          <NvIcon
            onClick={handleShowFeedBackInput}
            icon='comments'
            size='small'
            className='d-flex align-items-center text-primary'
            data-qa={dataQaQuizAttributes.questionModal.addFeedback}
          />
        </NvTooltip>
        )}
        {getIcons()}
      </div>
    </div>
  );
};

export default NumberAnswerSettingInput;
