import React, { useContext } from 'react';
import { css } from '@emotion/core';

// redux
import {
  NQuizQuestion,
} from 'redux/schemas/models/quiz';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';
import { editAnswerFeedback } from 'redux/actions/quizzes';

import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import FeedbackInput from './feedback-input';

type AnswerFeedbackProps = {
  currentQuestion: NQuizQuestion;
  triggerShowFeedback: boolean;
  setTriggerShowFeedback: (triggerShowFeedback: boolean) => void;
  correctAnswerFeedback: string;
  incorrectAnswerFeedback: string;
  setCorrectAnswerFeedback: (correctAnswerFeedback: string) => void;
  setIncorrectAnswerFeedback: (incorrectAnswerFeedback: string) => void;
};

export enum FocusAnswerFeedback {
  FOCUS_CORRECT_ANSWER_FEEDBACK = 'correct',
  FOCUS_INCORRECT_ANSWER_FEEDBACK = 'incorrect',
}

const AnswerFeedback = (props: AnswerFeedbackProps) => {
  const {
    correctAnswerFeedback,
    incorrectAnswerFeedback,
    setCorrectAnswerFeedback,
    setIncorrectAnswerFeedback,
    currentQuestion,
    triggerShowFeedback,
    setTriggerShowFeedback,
  } = props;

  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
  } = useContext(ProgressiveQuizContext);

  const {
    responseOptions,
  } = useContext(QuestionContext);

  const responseOptionId = responseOptions[0]?.id;

  const [focusAnswerFeedback, setFocusAnswerFeedback] = React.useState<FocusAnswerFeedback>(null);

  const dispatch = useAppDispatch();

  const styles = css`
    .feedback-content {
      .fr-placeholder {
        padding-left: ${halfSpacing}px !important;
      }
      .fr-element {
        padding-top: ${quarterSpacing}px !important;
        padding-bottom: ${quarterSpacing}px !important;
      }
      p {
        margin-bottom: 0px;
        padding-left: ${halfSpacing}px !important;
      }
    }
  `;

  const addFeedback = React.useCallback((explanationParam, secondaryExplanationParam) => {
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    wrapThunkAction(dispatch(editAnswerFeedback(
      { explanation: explanationParam,
        secondaryExplanation: secondaryExplanationParam,
        questionOptionId: responseOptionId },
    ))).then(() => {
      setCorrectAnswerFeedback(explanationParam);
      setIncorrectAnswerFeedback(secondaryExplanationParam);
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  }, [
    dispatch,
    responseOptionId,
    savingIndicatorTimeoutRef,
    setSavingStatus,
    setCorrectAnswerFeedback,
    setIncorrectAnswerFeedback,
  ]);

  React.useEffect(() => {
    if (triggerShowFeedback) {
      if (correctAnswerFeedback === null && incorrectAnswerFeedback !== null) {
        setFocusAnswerFeedback(FocusAnswerFeedback.FOCUS_CORRECT_ANSWER_FEEDBACK);
        addFeedback('', incorrectAnswerFeedback);
      }
      if (correctAnswerFeedback !== null && incorrectAnswerFeedback === null) {
        setFocusAnswerFeedback(FocusAnswerFeedback.FOCUS_INCORRECT_ANSWER_FEEDBACK);
        addFeedback(correctAnswerFeedback, '');
      }
      if (correctAnswerFeedback === null && incorrectAnswerFeedback === null) {
        setFocusAnswerFeedback(FocusAnswerFeedback.FOCUS_CORRECT_ANSWER_FEEDBACK);
        addFeedback('', '');
      }
      setTriggerShowFeedback(false);
    }
  }, [
    correctAnswerFeedback,
    incorrectAnswerFeedback,
    setTriggerShowFeedback,
    triggerShowFeedback,
    addFeedback,
  ]);

  return (
    <div css={styles} className='w-100'>
      {correctAnswerFeedback !== null
        && (
          <FeedbackInput
            isForCorrectAnswer
            correctAnswerFeedback={correctAnswerFeedback}
            incorrectAnswerFeedback={incorrectAnswerFeedback}
            setCorrectAnswerFeedback={setCorrectAnswerFeedback}
            currentQuestion={currentQuestion}
            focus={focusAnswerFeedback === FocusAnswerFeedback.FOCUS_CORRECT_ANSWER_FEEDBACK}
            setFocusAnswerFeedback={setFocusAnswerFeedback}
          />
        )}
      {incorrectAnswerFeedback !== null
        && (
          <FeedbackInput
            isForCorrectAnswer={false}
            correctAnswerFeedback={correctAnswerFeedback}
            incorrectAnswerFeedback={incorrectAnswerFeedback}
            setIncorrectAnswerFeedback={setIncorrectAnswerFeedback}
            currentQuestion={currentQuestion}
            focus={focusAnswerFeedback === FocusAnswerFeedback.FOCUS_INCORRECT_ANSWER_FEEDBACK}
            setFocusAnswerFeedback={setFocusAnswerFeedback}
          />
        )}
    </div>
  );
};

export default AnswerFeedback;
