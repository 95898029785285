import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/core';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';

// redux
import { editQuizQuestion } from 'redux/actions/quizzes';

import ClickableContainer from 'components/clickable-container';
import { isRtl } from 'styles/global_defaults/media-queries';
import { gray5, primary } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  quarterSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import ProgressiveQuizContext,
{
  SavingIndicator,
} from 'quizzes/components/context';
import { isEmpty } from 'underscore';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { config } from '../../../../../config/config.json';

type Limits = {
  maxLimit: number;
  minLimit: number;
};

type LimitSettingInputProps = {
  isMinLimitInput?: boolean;
  setLimits: Function;
  limits: Limits;
  isFocused: boolean;
  setIsFocused: Function;
};

const LimitSettingInput = (props: LimitSettingInputProps) => {
  const {
    setLimits,
    isMinLimitInput,
    limits,
    isFocused,
    setIsFocused,
  } = props;

  const [inputMode, setInputMode] = useState(true);
  const [inputContent, setInputContent] = useState(isMinLimitInput ? limits.minLimit : limits.maxLimit);

  useEffect(() => {
    if (isMinLimitInput) {
      if (limits.minLimit) {
        setInputMode(false);
        return;
      }
    }
    if (limits.maxLimit) {
      setInputMode(false);
    }
  }, [isMinLimitInput, limits.maxLimit, limits.minLimit]);

  const dispatch = useDispatch();

  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    currentQuestion,
  } = React.useContext(ProgressiveQuizContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);

  const inputRef = useRef(null);

  const handleBlur = (e) => {
    const inputValue = Number(e.target.value);

    const blurAction = (leftLimit: number, rightLimit: number) => {
      setInputMode(false);
      setIsFocused(false);

      const unregister = registerSaving();
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);

      dispatch(editQuizQuestion({
        id: currentQuestion.id,
        patch: {
          leftLimit,
          rightLimit,
        },
      })).then((res) => {
        if (isEmpty(res.error)) {
          setSavingStatus(SavingIndicator.SUCCESS_STATUS);
        } else {
          setSavingStatus(SavingIndicator.ERROR_STATUS);
        }
      }).finally(() => {
        unregister();
        savingIndicatorTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        }, 2000);
      });
    };

    if (!Number.isInteger(inputValue)) {
      // limit should be an integer
      return;
    }

    if (inputValue < 0 || inputValue > 1000) {
      // limit should be between 0 and 1000
      return;
    }
    if (isMinLimitInput) {
      // handling setting minimum limit

      if (inputValue === limits.maxLimit) {
        // preventing setting minLimit when it is equal to maxLimit
        return;
      }
      if (inputValue < limits.maxLimit || limits.maxLimit === null) {
        // setting minLimit
        setLimits({
          maxLimit: limits.maxLimit,
          minLimit: inputValue,
        });
        blurAction(inputValue, limits.maxLimit);
      } else if (inputValue > limits.maxLimit) {
        // swapping the limits when minLimit is greater than maxLimit
        setLimits(
          {
            minLimit: limits.maxLimit,
            maxLimit: inputValue,
          },
        );
        blurAction(limits.maxLimit, inputValue);
      }
      return;
    }
    // handling setting maximum limit

    if (inputValue === limits.minLimit) {
      // preventing setting maxLimit when it is equal to minLimit
      return;
    }
    if (inputValue > limits.minLimit || limits.minLimit === null) {
      // setting maxLimit
      setLimits({
        minLimit: limits.minLimit,
        maxLimit: inputValue,
      });
      blurAction(limits.minLimit, inputValue);
    } else if (inputValue < limits.minLimit) {
      // swapping the limits when maxLimit is greater than minLimit
      setLimits(
        {
          maxLimit: limits.minLimit,
          minLimit: inputValue,
        },
      );
      blurAction(inputValue, limits.minLimit);
    }
  };

  const switchToInputMode = () => {
    setInputMode(true);
    setIsFocused(true);
    setInputContent(isMinLimitInput ? limits.minLimit : limits.maxLimit);
    setTimeout(() => {
      inputRef?.current?.focus();
    });
  };

  const getBorderColor = () => {
    if (isFocused) {
      return primary;
    }
    return gray5;
  };

  const styles = css`
    .input-mode {
      border: 1px solid ${getBorderColor()};
      border-radius: ${quarterSpacing}px;
      position: relative;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      input {
        height: ${tripleSpacing}px !important;
        border-radius: ${quarterSpacing}px;
        background-color: white;
        border: 1px solid ${gray5};
        padding: 0px 2px 0px 2px !important;
        text-align: center;
        &:focus-visible {
          outline: none !important;
        }
      }
    }
    .view-mode {
      border: 1px solid ${gray5};
      border-radius: ${quarterSpacing}px;
      height: ${tripleSpacing}px !important;
      min-width: ${doubleSpacing}px;
      position: relative;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    .arrow {
      position: absolute;
      width: ${threeQuartersSpacing}px;
      height: ${threeQuartersSpacing}px;
      // Adjusting border-width for RTL: swaps left ↔ right to maintain correct border placement.
      border-width: ${isRtl() ? '0px 1px 1px 0px' : '0px 0px 1px 1px'};
      border-style: solid;
      border-color: ${getBorderColor()};
      background-color: white;
      transform: translate(-50%, -50%) rotate(-45deg);
      left: 50%;
    }
`;

  return (
    <div css={styles}>
      {inputMode ? (
        <div className='input-mode' css={styles}>
          <AutosizeInput
            ref={inputRef}
            tabIndex={0}
            onBlur={(e) => handleBlur(e)}
            type='number'
            className='p-3'
            placeholder='0'
            value={inputContent}
            onFocus={() => setIsFocused(true)}
            onChange={(e) => setInputContent(e.target.value)}
            data-qa={config.pendo.activities.quiz.questionModal.likertAnswerQuestion[isMinLimitInput ? 'addLeftLimit' : 'addRightLimit']}
          />
          <div className='arrow' />
        </div>
      ) : (
        <div>
          <ClickableContainer
            onClick={switchToInputMode}
            className='bold text-large-regular view-mode align-items-center justify-content-center'
            data-qa={config.pendo.activities.quiz.questionModal.likertAnswerQuestion[isMinLimitInput ? 'editLeftLimit' : 'editRightLimit']}
          >
            {isMinLimitInput ? limits.minLimit : limits.maxLimit}
          </ClickableContainer>
          <div className='arrow' />
        </div>
      )}
    </div>
  );
};

export default LimitSettingInput;
